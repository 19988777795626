<template>
  <v-card>
    <BoxHeader
      type="Notifications"
      @update="searchValue = $event" />
    <ToastSubmit :toastData="toast" v-if="toast.showToast"/>
    <EasyTable
      alternating
      buttons-pagination
      :rows-per-page="5"
      :fixed-header="false"
      :items="notificationsTmp"
      :hide-rows-per-page="true"
      :headers="notificationColumns"
      v-model:search-value="searchValue"
      table-class-name="adaptifytable-table">
      <template #header="header">
        <div class="adaptifytable-header">
          {{ header.text }}
        </div>
      </template>
      <template #empty-message>
        <ErrorLoader
          :error="error"
          :loading="loading"
          type="Notifications"
          :data="notificationsTmp"
          v-if="loading || error" />
        <AdaptifyAlert
          v-else
          type="info"
          :message="`No Data/Matches Found`" />
      </template>
      <template
        #item-acknowledgedcheck="{
          notificationId
        }">
        <div class="col-md-6">
          <input
            type="checkbox"
            v-model="this.notificationsTmp[this.getPropertyIndexById(notificationId)].acknowledged"
            @click="this.updateNotification(notificationId)"
          />
        </div>
      </template>
    </EasyTable>
  </v-card>
  <br />
</template>

<script>
import BoxHeader from './BoxHeader.vue';
import EasyTable from 'vue3-easy-data-table';
import ErrorLoader from '@/components/commons/ErrorLoader.vue';
import AdaptifyAlert from '../commons/AdaptifyAlert.vue';
import { toRef } from 'vue';
import ToastSubmit from './inputforms/ToastSubmit.vue';

export default {
  props: {
    notifications: Array,
  },
  setup(props) {
    const notificationsTmp = toRef(props, 'notifications');
    return {
      notificationsTmp,
    };
  },
  emits: ['refreshNotifications'],
  async created() {
    this.notificationsInverval = setInterval(async() => {
      this.$emit('refreshNotifications');
    }, 30000);
  },
  methods: {
    updateNotification(notificationId) {
      const notificationKey = this.getPropertyIndexById(notificationId);
      this.notificationsTmp[notificationKey].acknowledged = !this.notificationsTmp[notificationKey].acknowledged;
      this.notificationsTmp[notificationKey].acknowledgedBy = this.$keycloak.tokenParsed.sub;
      const body = this.notificationsTmp[notificationKey];
      this.$http
        .put(
          `${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${body.organization.organizationId}/notification`,
          body
        )
        .then(response => {
          this.toastSuccess('Notification updated successfully!');
        })
        .catch((error) => {
          this.toastError(error, 'Unable to update notification.')
        });
    },
    getPropertyIndexById(notificationId) {
      return this.notificationsTmp.findIndex(notification => notification.notificationId === notificationId);
    },
  },
  unmounted() {
    clearInterval(this.notificationsInverval);
  },
  data() {
    return {
      error: null,
      notificationsInverval: null,
      loading: false,
      searchValue: "",
      notificationEdit: [],
      notificationColumns: [
        {
          text: 'Name',
          value: 'alert.alertType.description',
        },
        {
          text: 'Detail',
          value: 'detail',
        },
        {
          text: 'Created On',
          value: 'createdDate',
        },
        {
          text: 'Acknowledge',
          value: 'acknowledgedcheck',
        },
      ],
    };
  },
  components: {
    BoxHeader,
    EasyTable,
    ErrorLoader,
    AdaptifyAlert,
    ToastSubmit,
  },
};
</script>
