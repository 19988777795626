<template>
  <CustomerHeader/>
  <div className="container-fluid">
    <AdaptersBox :organizationId="$keycloak.tokenParsed.organizationId" />
    <UserBox :organizationId="$keycloak.tokenParsed.organizationId" />
  </div>
</template>

<script>
import '../assets/css/adaptifytables.css';
import 'vue3-easy-data-table/dist/style.css';
import UserBox from '@/components/databoxes/UserBox.vue';
import CustomerHeader from '../components/CustomerHeader.vue';
import AdaptersBox from '@/components/databoxes/AdaptersBox.vue';

export default {
  components: {
    UserBox,
    AdaptersBox,
    CustomerHeader,
  },
};
</script>
