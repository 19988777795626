<template>
  <v-card>
    <BoxHeader
      type="Services"
      :action="
        () => {
          productEdit = null;
          showModalAddEditProduct = true;
        }
      "
      @update="searchValue = $event" />
    <EasyTable
      alternating
      :items="prods"
      :rows-per-page="5"
      buttons-pagination
      :fixed-header="false"
      :headers="productColumns"
      :hide-rows-per-page="true"
      v-model:search-value="searchValue"
      table-class-name="adaptifytable-table">
      <template #header="header">
        <div class="adaptifytable-header">
          {{ header.text }}
        </div>
      </template>
      <template #empty-message>
        <ErrorLoader
          :data="prods"
          :error="error"
          type="Products"
          :loading="loading"
          v-if="loading || error" />
        <AdaptifyAlert
          v-else
          type="info"
          :message="`No Data/Matches Found`" />
      </template>
      <template #item-productId="{ productId }">
        <CiscoButton
          color="blue"
          type="pencil"
          :action="() => this.openEditProduct(productId)" />
        <CiscoButton
          type="more"
          color="blue"
          :action="() => viewProduct(productId)" />
      </template>
      <template #item-active="{ active }">
        <span>
          <label class="switch">
            <input
              v-if="active"
              type="checkbox"
              checked
              disabled />
            <input
              v-else
              type="checkbox"
              disabled />
            <span class="switch__input" />
          </label>
        </span>
      </template>
    </EasyTable>
  </v-card>
  <AddEditProduct
    @refresh="getProds()"
    :productEdit="productEdit"
    v-if="showModalAddEditProduct"
    :organizationId="organizationId"
    v-model="showModalAddEditProduct"
    @update="showModalAddEditProduct = $event"
    :showModalAddEditProduct="showModalAddEditProduct" />
  <br />
</template>

<script>
import { useRouter } from 'vue-router';
import BoxHeader from './BoxHeader.vue';
import EasyTable from 'vue3-easy-data-table';
import CiscoButton from '../commons/CiscoButton.vue';
import AdaptifyAlert from '../commons/AdaptifyAlert.vue';
import AddEditProduct from './inputforms/AddEditProduct.vue';
import ErrorLoader from '@/components/commons/ErrorLoader.vue';

export default {
  props: {
    organizationId: String,
  },
  data() {
    return {
      prods: [],
      error: null,
      loading: false,
      searchValue: '',
      productEdit: null,
      showModalAddEditProduct: false,
      productColumns: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: '',
          value: 'productId',
        },
        {
          text: 'Active',
          value: 'active',
        },
        {
          text: 'Release Name',
          value: 'releaseName',
        },
        {
          text: 'Description',
          value: 'description',
        },
        {
          text: 'Created Date',
          value: 'createdDate',
        },
        {
          text: 'Updated Date',
          value: 'updatedDate',
        },
      ],
    };
  },
  async created() {
    this.getProds();
  },
  methods: {
    async getProds() {
      this.loading = true;
      await this.$http
        .get(`${this.config.VUE_APP_ENV_URL}/c5server/v1/product`)
        .then((response) => (this.prods = response.data))
        .catch((e) => {
          this.error = `Unable to display products. Server responded with "${e.message}"`;
          console.error(e);
        });
      this.loading = false;
    },
    async openEditProduct(edit) {
      this.loading = true;
      await this.$http
        .get(`${this.config.VUE_APP_ENV_URL}/c5server/v1/product/${edit}`)
        .then((response) => {
          this.productEdit = response.data;
          this.showModalAddEditProduct = true;
        })
        .catch((e) => {
          this.error = `Unable to get product info. Server responded with "${e.message}"`;
          console.error(e);
        });
      this.loading = false;
    },
  },
  setup() {
    const router = useRouter();
    return {
      viewProduct: (productId) => router.push(`/product/${productId}`),
    };
  },
  components: {
    BoxHeader,
    EasyTable,
    ErrorLoader,
    CiscoButton,
    AddEditProduct,
    AdaptifyAlert,
  },
};
</script>
