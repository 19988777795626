<template>
  <v-card>
    <BoxHeader
      type="Contacts"
      :action="
        () => {
          contactEdit = null;
          showModalAddEditContact = !showModalAddEditContact;
        }
      "
      @update="searchValue = $event" />
    <EasyTable
      alternating
      :items="contacts"
      buttons-pagination
      :rows-per-page="5"
      :fixed-header="false"
      :headers="contactColumns"
      :hide-rows-per-page="true"
      v-model:search-value="searchValue"
      table-class-name="adaptifytable-table">
      <template #header="header">
        <div class="adaptifytable-header">
          {{ header.text }}
        </div>
      </template>
      <template #empty-message>
        <ErrorLoader
          type="Contacts"
          :data="contacts"
          :loading="loading"
          v-if="loading || error" />
        <AdaptifyAlert
          v-else
          type="info"
          :message="`No Data/Matches Found`" />
      </template>
      <template #item-contactId="{ contactId }">
        <CiscoButton
          color="blue"
          type="pencil"
          :action="() => openEditContact(contactId)" />
      </template>
      <template #item-active="{ active }">
        <span>
          <label class="switch">
            <input
              v-if="active"
              type="checkbox"
              checked
              disabled />
            <input
              v-else
              type="checkbox"
              disabled />
            <span class="switch__input" />
          </label>
        </span>
      </template>
    </EasyTable>
  </v-card>
  <br />
  <AddEditContact
    :customer="customer"
    @refresh="refresh()"
    :contactEdit="contactEdit"
    v-model="showModalAddEditContact"
    v-if="this.showModalAddEditContact"
    @update="showModalAddEditContact = $event"
    :showModalAddEditContact="showModalAddEditContact" />
</template>

<script>
import BoxHeader from './BoxHeader.vue';
import EasyTable from 'vue3-easy-data-table';
import CiscoButton from '../commons/CiscoButton.vue';
import AdaptifyAlert from '../commons/AdaptifyAlert.vue';
import AddEditContact from './inputforms/AddEditContact.vue';
import ErrorLoader from '@/components/commons/ErrorLoader.vue';

export default {
  props: {
    contacts: Array,
    customer: Object,
    refresh: Function,
    organizationId: String,
  },
  data() {
    return {
      loading: false,
      searchValue: '',
      contactEdit: null,
      showModalAddEditContact: false,
      contactColumns: [
        {
          text: 'First Name',
          value: 'firstName',
        },
        {
          text: 'Edit',
          value: 'contactId',
        },
        {
          text: 'Last Name',
          value: 'lastName',
        },
        {
          text: 'Active',
          value: 'active',
        },
        {
          text: 'Address',
          value: 'address',
        },
        {
          text: 'City',
          value: 'city',
        },
        {
          text: 'State',
          value: 'state',
        },
        {
          text: 'Zip Code',
          value: 'zipCode',
        },
        {
          text: 'Phone Number',
          value: 'phoneNumber',
        },
        {
          text: 'Email',
          value: 'emailAddress',
        },
      ],
    };
  },
  methods: {
    async openEditContact(edit) {
      this.contactEdit = this.contacts.find((contact) => contact.contactId === edit);
      this.showModalAddEditContact = true;
    },
  },
  components: {
    BoxHeader,
    EasyTable,
    ErrorLoader,
    CiscoButton,
    AddEditContact,
    AdaptifyAlert,
  },
};
</script>
