<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <AdminNav v-bind:currentUser="currentUser" />
  <div className="container-fluid">
    <ErrorLoader
      :loading="loading"
      :data="customer"
      :type="`Customer`"
      :error="error" />
    <span
      className="row"
      v-if="!loading && customer">
      <h1 class="col-6">
        {{ customer.name }}
      </h1>
      <span class="col-6 flex flex-right">
        <CiscoButton
          color="blue"
          type="pencil"
          label="Edit Customer"
          :action="
            () => {
              customerEdit = customer;
              showModalAddEditCustomer = !showModalAddEditCustomer;
            }
          " />
      </span>
    </span>
    <div
      class="row flex flex-left"
      v-if="!loading && customer">
      <div class="col-3">
        <span class="labels">Created Date</span>
        <div>{{ customer.createdDate }}</div>
        <span class="labels">updatedDate</span>
        <div>{{ customer.updatedDate }}</div>
      </div>
      <div class="col-9">
        <span class="labels">Active</span>
        <div>{{ customer.active.toString() }}</div>
        <span class="labels">Description</span>
        <div>{{ customer.description }}</div>
      </div>
    </div>
    <AdaptersBox
      type="admin"
      :organizationId="organizationId" />
    <UserBox :organizationId="organizationId" />
    <NotificationsBox
      :notifications="notifications" @refreshNotifications="getNotifications"/>
    <ContactBox
      v-if="customer"
      :contacts="contacts"
      :customer="customer"
      :refresh="getCustomer"
      :organizationId="organizationId" />
    <AddEditCustomer
      @refresh="getCustomer()"
      :customerEdit="customerEdit"
      v-if="showModalAddEditCustomer"
      v-model="showModalAddEditCustomer"
      @update="showModalAddEditCustomer = $event"
      :showModalAddEditCustomer="showModalAddEditCustomer" />
    <br />
  </div>
</template>

<script>
import AdminNav from '@/components/AdminHeader.vue';
import UserBox from '@/components/databoxes/UserBox.vue';
import ContactBox from '@/components/databoxes/ContactBox.vue';
import ErrorLoader from '@/components/commons/ErrorLoader.vue';
import CiscoButton from '@/components/commons/CiscoButton.vue';
import AdaptersBox from '@/components/databoxes/AdaptersBox.vue';
import NotificationsBox from '@/components/databoxes/NotificationsBox.vue';
import AddEditCustomer from '@/components/databoxes/inputforms/AddEditCustomer.vue';

export default {
  data() {
    return {
      error: null,
      notifications: [],
      loading: false,
      customer: null,
      contacts: null,
      currentUser: null,
      customerEdit: null,
      organizationId: null,
      showModalAddEditCustomer: false,
    };
  },
  async created() {
    this.organizationId = this.$route.params.id;
    this.getCustomer();
    this.getCurrentUser(
      this.$keycloak.tokenParsed.organizationId,
      this.$keycloak.tokenParsed.sub
    );
    this.getNotifications();
  },
  methods: {
    getCustomer() {
      this.loading = true;
      this.$http
        .get(
          `${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${this.organizationId}`
        )
        .then((response) => {
          if (response.status == 200) {
            this.customer = response.data;
            this.contacts = response.data.contacts;
          } else {
            this.error = `Unable to display customers. Server responded with "${response.status}"`;
            console.log(this.error);
          }
        })
        .finally(() => {
          this.loading = false;
        })
        .catch((e) => {
          this.error = `Unable to display customer. Server responded with "${e.message}"`;
          console.error(e);
        });
    },
    getNotifications() {
      this.$http
        .get(
          `${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${this.organizationId}/notification`
        )
        .then((response) => {
          if (response.status == 200) {
            this.notifications = response.data;
          } else {
            this.error = `Unable to display notifications. Server responded with "${response.status}"`;
            console.error(this.error);
          }
        })
        .catch((e) => {
          this.error = `Unable to display notifications. Server responded with "${e.message}"`;
          console.error(e);
        });
    },
    getCurrentUser(organizationId, userId) {
      this.$http
        .get(
          `${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${organizationId}/user/${userId}`
        )
        .then((response) => {
          if (response.status == 200) {
            this.currentUser = response.data;
          } else {
            this.error = `Unable to get current user. Server responded with "${response.status}"`;
            console.log(this.error);
          }
        })
        .finally(() => {
          this.isShowLoadingProducts = false;
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
  components: {
    UserBox,
    AdminNav,
    ContactBox,
    AdaptersBox,
    ErrorLoader,
    AddEditCustomer,
    NotificationsBox,
    CiscoButton,
  },
};
</script>

<style>
.labels {
  font-weight: bold;
}
</style>
