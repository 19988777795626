<template>
  <DialogWrapper
    :show="showModalAddEditProps"
    :header="`${propEdit ? 'Edit' : 'Add'} Props`">
    <ToastSubmit
      :toastData="toast"
      v-if="toast.showToast" />
    <span class="row">
      <span class="col-9">
        <InputErrorWrapper
          property="propName"
          :validate="validate">
          <div class="form-group__text">
            <input
              type="text"
              v-model="prop.propName" />
            <label for="name">Name</label>
          </div>
        </InputErrorWrapper>
      </span>
      <span class="col-3 flex flex-right">
        <div>
          <div class="subheader">Required</div>
          <label class="switch">
            <input
              type="checkbox"
              v-model="prop.propRequired" />
            <span class="switch__input" />
          </label>
        </div>
      </span>
    </span>
    <span class="row">
      <span class="col-6">
        <InputErrorWrapper
          property="propDisplayName"
          :validate="validate">
          <div class="form-group__text">
            <input
              type="text"
              v-model="prop.propDisplayName" />
            <label>Display Name</label>
          </div>
        </InputErrorWrapper>
      </span>
      <span class="col-6">
        <InputErrorWrapper
          property="propValue"
          :validate="validate">
          <div class="form-group__text">
            <input
              type="text"
              v-model="prop.propValue" />
            <label>Prop Value</label>
          </div>
        </InputErrorWrapper>
      </span>
    </span>
    <InputErrorWrapper
      property="propDescription"
      :validate="validate">
      <div class="form-group__text">
        <textarea
          rows="4"
          v-model="prop.propDescription" />
        <label>Description</label>
      </div>
    </InputErrorWrapper>
    <span class="row">
      <span class="col-6">
        <InputErrorWrapper
          property="displayOrder"
          :validate="validate">
          <div class="form-group__text">
            <input
              type="number"
              v-model="prop.displayOrder" />
            <label>Display Order</label>
          </div>
        </InputErrorWrapper>
      </span>
      <span class="col-6">
        <InputErrorWrapper
          property="propType"
          :validate="validate">
          <div class="form-group__text">
            <input
              type="text"
              v-model="prop.propType" />
            <label>Prop Type</label>
          </div>
        </InputErrorWrapper>
      </span>
    </span>
    <AddEditDeleteButtons
      type="Prop"
      :add="() => handleSubmit(addEditProp)"
      :dataEdit="propEdit"
      :update="() => handleSubmit(() => addEditProp(prop.propId))"
      :remove="() => handleSubmit(() => deleteProp(prop.propId))" />
  </DialogWrapper>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import ToastSubmit from './ToastSubmit.vue';
import DialogWrapper from './DialogWrapper.vue';
import { required } from '@vuelidate/validators';
import InputErrorWrapper from './InputErrorWrapper.vue';
import AddEditDeleteButtons from './AddEditDeleteButtons.vue';

export default {
  props: {
    product: Object,
    propEdit: Object,
    refresh: Function,
    variantEdit: Object,
    showModalAddEditProps: Boolean,
  },
  data() {
    return {
      productVariant: null,
      prop: {
        propId: null,
        propName: null,
        propValue: null,
        propType: null,
        propRequired: true,
        propDisplayName: null,
        propDescription: null,
        displayOrder: null,
      },
      rules: {
        propName: { required },
        propValue: { required },
        propType: { required },
        propRequired: { required },
        propDisplayName: { required },
        propDescription: { required },
        displayOrder: { required },
      },
      validate: null,
    };
  },
  async created() {
    this.productEdit = this.clone(this.product);
    console.log(this.product.productVariants);
    console.log(this.variantEdit);
    this.productVariant = this.clone(this.variantEdit);
    if (this.propEdit) {
      this.prop = this.clone(this.propEdit);
    }
  },
  methods: {
    async addEditProp(id) {
      this.toast.showToast = false;
      const body = this.product;
      if (id) {
        const oldProp = this.productVariant.productVariantProps.find(
          (p) => p.propId === id
        );
        const index = this.productVariant.productVariantProps.indexOf(oldProp);
        this.productVariant.productVariantProps.splice(index, 1, this.prop);
      } else {
        delete this.propId;
        this.productVariant.productVariantProps.push(this.prop);
      }
      const variant = body.productVariants.find(
        (v) => v.productVariantId === this.productVariant.productVariantId
      );
      const index = body.productVariants.indexOf(variant);
      body.productVariants.splice(index, 1, this.productVariant);
      this.$http
        .put(`${this.config.VUE_APP_ENV_URL}/c5server/v1/product/`, body)
        .then(this.toastSuccess('Props updated successfully!'))
        .catch((error) => this.toastError(error, 'Unable to update props.'));
    },
    async deleteProp(id) {
      this.toast.showToast = false;
      const body = this.product;
      const oldProp = this.productVariant.productVariantProps.find((p) => p.propId === id);
      const propIndex = this.productVariant.productVariantProps.indexOf(oldProp);
      this.productVariant.productVariantProps.splice(propIndex, 1);
      const variant = body.productVariants.find(
        (v) => v.productVariantId === this.productVariant.productVariantId
      );
      const variantIndex = body.productVariants.indexOf(variant);
      body.productVariants.splice(variantIndex, 1, this.productVariant);
      this.$http
        .put(`${this.config.VUE_APP_ENV_URL}/c5server/v1/product/`, body)
        .then(this.toastSuccess('Props updated successfully!'))
        .catch((error) => this.toastError(error, 'Unable to update props.'));
    },
    async handleSubmit(apiCall) {
      const v$ = useVuelidate(this.rules, this.prop);
      const result = await v$.value.$validate();
      this.validate = v$;
      if (result) {
        apiCall();
      }
    },
    handleClose() {
      this.$emit('refresh');
      this.$emit('update', true);
    },
  },
  components: {
    ToastSubmit,
    DialogWrapper,
    InputErrorWrapper,
    AddEditDeleteButtons,
  },
};
</script>
