<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <AdminNav
    v-bind:organization="org"
    v-bind:currentUser="currentUser" />
  <div class="content --alt">
    <div className="container-fluid">
      <CustomerBox v-bind:organizationId="organizationId" />
    </div>
  </div>
</template>

<script>
import AdminNav from '../components/AdminHeader.vue';
import CustomerBox from '../components/databoxes/CustomerBox.vue';

export default {
  data() {
    return {
      loading: false,
      org: {},
      currentUser: null,
      organizationId: null,
    };
  },
  async created() {
    this.organizationId = this.$keycloak.tokenParsed.organizationId;
    this.getCurrentUser(
      this.$keycloak.tokenParsed.organizationId,
      this.$keycloak.tokenParsed.sub
    );
  },
  methods: {
    async getCurrentUser(organizationId, userId) {
      await this.$http
        .get(
          `${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${organizationId}/user/${userId}`
        )
        .then((response) => {
          if (response.status == 200) {
            this.currentUser = response.data;
          } else {
            this.error = `Unable to display adapters. Server responded with "${response.status}"`; // Once toast are merged, throw error with toast
            console.log(this.error);
          }
        })
        .finally(() => {
          this.isShowLoadingProducts = false;
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
  components: {
    AdminNav,
    CustomerBox,
  },
};
</script>
