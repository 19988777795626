<template>
  <div
    class="loader loader--large"
    aria-label="Loading, please wait...">
    <div class="wrapper">
      <div class="wheel"></div>
    </div>
    <div class="flex flex-center subheader">Loading...</div>
  </div>
</template>
