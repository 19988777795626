<template>
  <span class="flex flex-right">
    <CiscoButton
      v-if="reset"
      type="refresh"
      color="blue"
      label="Reset Password"
      :isDisabled = false
      :action="reset" />
    <CiscoButton
      v-if="!dataEdit && add"
      type="plus"
      color="blue"
      :label="`Add ${type}`"
      :isDisabled = false
      :action="add" />
    <CiscoButton
      v-else-if="update"
      type="save"
      color="blue"
      label="Save Changes"
      :isDisabled = false
      :action="update" />
    <CiscoButton
      v-if="dataEdit && remove"
      type="trash"
      color="blue"
      :isDisabled = false
      :label="`Delete ${type}`"
      :action="remove" />
    <CiscoButton
    v-if="error"
    type="warning"
    color="red"
    :isDisabled = true
    :label="` ${type}`"
    :action="error" />
  </span>
</template>

<script>
import CiscoButton from '@/components/commons/CiscoButton.vue';

export default {
  props: {
    type: String,
    add: Function,
    reset: Function,
    dataEdit: Object,
    update: Function,
    remove: Function,
    error: Function,
    variantEdit: Object,
  },
  components: { CiscoButton },
};
</script>
