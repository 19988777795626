<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <AdminNav />
  <div class="content --alt">
    <div className="container-fluid">
      <div className="section">
        <div className="flex-center-vertical">
          <h1>Orders</h1>
        </div>
      </div>
      <div class="form-group form-group--inline">
        <div class="form-group__text">
          <input v-model="ordSearchName" />
          <label for="name">Search</label>
        </div>
      </div>
      <div class="form-group form-group--inline">
        <label class="switch">
          <input
            type="checkbox"
            v-model="showActiveOrders" />
          <span class="switch__label">Active</span>
          <span class="switch__input"></span>
        </label>
      </div>
      <div class="form-group form-group--inline">
        <button
          class="btn btn--primary"
          v-on:click="filterOrders()">
          Search
        </button>
      </div>
      <div class="form-group form-group--inline">
        <button
          class="btn btn--ghost"
          v-on:click="showNewOrders = !showNewOrders">
          New Orders
        </button>
      </div>
      <div className="section">
        <div
          class="flex flex-center"
          v-if="error"
          v-on:click="error = null">
          <AdaptifyAlert :message="error" />
        </div>
        <div
          class="flex flex-center"
          v-if="loading">
          <SpinLoader />
        </div>
        <div
          class="flex flex-center"
          v-if="!loading && error === null && orders && !orders.length">
          <h1>No Orders Found</h1>
        </div>
        <div
          className="responsive-table"
          v-if="!loading && orders && orders.length > 0">
          <table className="table table--lined table--selectable">
            <thead>
              <tr>
                <th>Name</th>
                <th class="text-center">Active</th>
                <th class="hidden-md-down">Release Name</th>
                <th class="hidden-md-down">Version</th>
                <th class="hidden-md-down">Description</th>
                <th class="hidden-md-down">Created</th>
                <th class="hidden-lg-down">Updated</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="order in orders"
                :key="order.productId"
                v-on:click="viewOrder(order.orderId)">
                <td>{{ order.name }}</td>
                <td class="text-center">
                  {{ order.active.toString() }}
                </td>
                <td class="hidden-md-down">
                  {{ order.releaseName }}
                </td>
                <td class="hidden-md-down">
                  {{ order.releaseVersion }}
                </td>
                <td class="hidden-md-down">
                  {{ order.description }}
                </td>
                <td class="hidden-md-down">
                  {{ order.createdDate }}
                </td>
                <td class="hidden-lg-down">
                  {{ order.updatedDate }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      class="modal modal--large"
      v-if="showNewOrders">
      <div class="modal__dialog">
        <div class="modal__content">
          <a
            class="modal__close"
            v-on:click="showNewOrders = !showNewOrders"
            ><span class="icon-close"></span
          ></a>
          <div class="modal__header">
            <div class="modal__title">New Order</div>
            <hr />
          </div>
          <div class="modal__body">
            <div class="form-group form-group--inline">
              <div class="form-group__text">
                <input
                  type="text"
                  v-model="newOrder.name" />
                <label for="name">Name</label>
              </div>
            </div>
            <div class="form-group form-group--inline">
              <label class="switch">
                <input
                  type="checkbox"
                  v-model="newOrder.active" />
                <span class="switch__label">Active</span>
                <span class="switch__input" />
              </label>
            </div>
            <div class="form-group base-margin-bottom">
              <div class="form-group__text">
                <textarea
                  rows="3"
                  v-model="newOrder.description" />
                <label for="textarea-state-default"> Description </label>
              </div>
            </div>
          </div>
          <div class="modal__footer">
            <button
              class="btn"
              v-on:click="makeNewOrder">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import AdminNav from '../components/AdminHeader.vue';
import SpinLoader from '../components/commons/SpinLoader.vue';
import AdaptifyAlert from '../components/commons/AdaptifyAlert.vue';

export default {
  data() {
    return {
      showNewOrders: false,
      showActiveOrders: false,
      loading: false,
      ordSearchName: null,
      error: null,
      orders: [],
      newOrder: {
        name: null,
        releaseDate: null,
        releaseName: null,
        description: null,
        active: true,
        createdByUserId: 'Nikky Soriano', // Change to session holder
      },
    };
  },
  async created() {
    this.getOrders();
  },
  methods: {
    getOrders() {
      this.loading = true;
      this.$http
        .get(`${this.config.VUE_APP_ENV_URL}/c5server/v1/product`)
        .then((response) => {
          if (response.status == 200) {
            this.orders = response.data;
          } else {
            this.error = `Unable to display orders. Server responded with "${response.status}"`; // Once toast are merged, throw error with toast
            console.log(this.error);
          }
        })
        .finally(() => {
          this.loading = false;
        })
        .catch((e) => {
          this.error = `Unable to display orders. Server responded with "${e.message}"`;
          console.error(e);
        });
    },
    makeNewOrder() {
      this.loading = true;
      const body = this.newOrder;
      body.contacts = [this.contact];
      this.$http
        .post(`${this.config.VUE_APP_ENV_URL}/c5server/v1/organization`, body)
        .then((response) => {
          if (response.status == 200) {
            this.getOrders();
          } else {
            this.error = `Unable to create new order. Server responded with status ${response.status}`;
            console.log(this.error);
          }
        })
        .finally(() => {
          this.showNewOrders = !this.showNewOrders;
          this.loading = false;
        })
        .catch((e) => {
          this.error = `Unable to create new order. Server responded with "${e.message}"`;
          console.error(e);
        });
    },
    filterOrders() {
      this.loading = true;
      if (this.showActiveOrders) {
        this.orders = this.orders.filter((order) => order.active);
      }
      if (this.ordSearchName) {
        this.orders = this.orders.filter((order) => order.name == this.ordSearchName);
      }
      this.loading = false;
    },
  },
  setup() {
    const router = useRouter();
    function viewOrder(orderId) {
      router.push(`/orders/${orderId}`);
    }
    return {
      viewOrder,
    };
  },
  components: {
    AdminNav,
    SpinLoader,
    AdaptifyAlert,
  },
};
</script>
