<template>
    <AdminNav v-bind:currentUser="currentUser" />
    <div class="not-found">
      <img src="@/assets/C5-Logo.png"/>
      <h1>404</h1>
      <p>Page Not Found</p>
      <router-link to="/">Go Back Home</router-link>
    </div>
  </template>
  
<script>
import AdminNav from '../components/AdminHeader.vue';

export default {
  name: 'NotFoundPage',
  components: {
    AdminNav
  }
};
</script>

  <style scoped>
  .not-found {
    text-align: center;
    margin-top: 50px;
  }
  
  .not-found h1 {
    font-size: 6em;
  }
  
  .not-found p {
    font-size: 2em;
  }
  </style>