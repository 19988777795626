<template>
  <AdminNav v-bind:currentUser="currentUser" />
  <div className="container-fluid">
    <UserBox v-bind:organizationId="organizationId" />
    <ProductBox v-bind:organizationId="organizationId" />
    <CustomerBox v-bind:organizationId="organizationId" />
  </div>
</template>

<script>
import AdminNav from '../components/AdminHeader.vue';
import UserBox from '@/components/databoxes/UserBox.vue';
import ProductBox from '@/components/databoxes/ProductBox.vue';
import CustomerBox from '@/components/databoxes/CustomerBox.vue';

export default {
  data() {
    return {
      loading: false,
      currentUser: null,
      organizationId: null,
    };
  },
  async created() {
    this.organizationId = this.$keycloak.tokenParsed.organizationId;
    await this.getCurrentUser(this.$keycloak.tokenParsed.organizationId, this.$keycloak.tokenParsed.sub);
  },
  methods: {
    getCurrentUser(organizationId, userId) {
      this.$http
        .get(
          `${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${organizationId}/user/${userId}`
        )
        .then((response) => {
          this.currentUser = response.data
        })
        .catch((error) => console.error(error));
    },
  },
  components: {
    AdminNav,
    UserBox,
    ProductBox,
    CustomerBox,
  },
};
</script>
