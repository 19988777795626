<template>
  <v-card>
    <BoxHeader
      type="Props"
      :variant="variantEdit.name"
      :close="handleClose"
      :action="
        () => {
          propEdit = null;
          showModalAddEditProps = !showModalAddEditProps;
        }
      "
      @update="searchValue = $event" />
    <EasyTable
      alternating
      :items="props"
      buttons-pagination
      :rows-per-page="5"
      :fixed-header="false"
      :headers="propColumns"
      :hide-rows-per-page="true"
      v-model:search-value="searchValue"
      table-class-name="adaptifytable-table">
      <template #header="header">
        <div class="adaptifytable-header">
          {{ header.text }}
        </div>
      </template>
      <template #empty-message>
        <ErrorLoader
          v-bind:error="error"
          v-bind:data="props"
          v-bind:type="`Props`"
          v-bind:loading="loading"
          v-if="loading || error" />
        <AdaptifyAlert
          v-else
          type="info"
          :message="`No Data/Matches Found`" />
      </template>
      <template #item-propId="{ propId }">
        <CiscoButton
          color="blue"
          type="pencil"
          :action="() => openEditProp(propId)" />
      </template>
      <template #item-propRequired="{ propRequired }">
        <span>
          <label class="switch">
            <input
              v-if="propRequired"
              type="checkbox"
              checked
              disabled />
            <input
              v-else
              type="checkbox"
              disabled />
            <span class="switch__input" />
          </label>
        </span>
      </template>
    </EasyTable>
  </v-card>
  <AddEditProps
    :product="product"
    @refresh="refresh()"
    :propEdit="propEdit"
    :variantEdit="variantEdit"
    v-if="showModalAddEditProps"
    v-model="showModalAddEditProps"
    @update="showModalAddEditProps = $event"
    :showModalAddEditProps="showModalAddEditProps" />
</template>

<script>
import BoxHeader from './BoxHeader.vue';
import EasyTable from 'vue3-easy-data-table';
import CiscoButton from '../commons/CiscoButton.vue';
import AddEditProps from './inputforms/AddEditProps.vue';
import AdaptifyAlert from '../commons/AdaptifyAlert.vue';
import ErrorLoader from '@/components/commons/ErrorLoader.vue';

export default {
  props: {
    props: Array,
    product: Object,
    refresh: Function,
    showProps: Boolean,
    variantEdit: Object,
  },
  data() {
    return {
      error: null,
      loading: false,
      propEdit: null,
      showModalAddEditProps: false,
      propColumns: [
        {
          text: 'Name',
          value: 'propName',
        },
        {
          text: 'Edit',
          value: 'propId',
        },
        {
          text: 'Type',
          value: 'propType',
        },
        {
          text: 'Required',
          value: 'propRequired',
        },
        {
          text: 'Display Name',
          value: 'propDisplayName',
        },
        {
          text: 'Description',
          value: 'propDescription',
        },
        {
          text: 'Display Order',
          value: 'displayOrder',
        },
      ],
    };
  },
  methods: {
    handleClose() {
      this.$emit('update:showProps', false);
    },
    openEditProp(propId) {
      this.propEdit = this.props.find((p) => p.propId === propId);
      this.showModalAddEditProps = true;
    },
  },
  components: {
    BoxHeader,
    EasyTable,
    ErrorLoader,
    CiscoButton,
    AddEditProps,
    AdaptifyAlert,
  },
};
</script>
