<template>
  <DialogWrapper
    :show="showModalAddEditProduct"
    :header="`${productEdit ? 'Edit' : 'Add'} Service`">
    <ToastSubmit
      :toastData="toast"
      v-if="toast.showToast" />
    <span class="row">
      <span class="col-10">
        <InputErrorWrapper
          property="name"
          :validate="validate">
          <div class="form-group__text">
            <input
              type="text"
              v-model="product.name" />
            <label>Name</label>
          </div>
        </InputErrorWrapper>
      </span>
      <span class="col-2 flex flex-right">
        <div class="form-group">
          <div class="subheader">Active</div>
          <label class="switch">
            <input
              type="checkbox"
              v-model="product.active" />
            <span class="switch__input" />
          </label>
        </div>
      </span>
    </span>
    <span class="row">
      <span class="col-6">
        <InputErrorWrapper
          property="releaseName"
          :validate="validate">
          <div class="form-group__text">
            <input
              type="text"
              v-model="product.releaseName" />
            <label>Release Name</label>
          </div>
        </InputErrorWrapper>
      </span>
      <span class="col-6">
        <InputErrorWrapper
          property="releaseVersion"
          :validate="validate">
          <div class="form-group__text">
            <input
              type="text"
              v-model="product.releaseVersion" />
            <label>Release Version</label>
          </div>
        </InputErrorWrapper>
      </span>
    </span>
    <InputErrorWrapper
      property="releaseDate"
      :validate="validate">
      <div class="form-group__text">
        <input
          type="date"
          v-model="product.releaseDate" />
        <label for="input-type-date">Release Date</label>
      </div>
    </InputErrorWrapper>
    <InputErrorWrapper
      property="description"
      :validate="validate">
      <div class="form-group__text">
        <textarea
          rows="4"
          v-model="product.description" />
        <label>Description</label>
      </div>
    </InputErrorWrapper>
    <AddEditDeleteButtons
      type="Service"
      :dataEdit="productEdit"
      :add="() => handleSubmit(addProduct)"
      :update="() => handleSubmit(updateProduct)"
      :remove="() => handleSubmit(() => deleteProduct(this.productEdit.productId))" />
  </DialogWrapper>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import ToastSubmit from './ToastSubmit.vue';
import DialogWrapper from './DialogWrapper.vue';
import { required } from '@vuelidate/validators';
import adapters from '../../../assets/adapters.json';
import InputErrorWrapper from './InputErrorWrapper.vue';
import AddEditDeleteButtons from './AddEditDeleteButtons.vue';

export default {
  props: {
    productEdit: Object,
    organizationId: String,
    showModalAddEditProduct: Boolean,
  },
  data() {
    return {
      product: {
        productId: null,
        name: null,
        releaseDate: null,
        releaseName: null,
        releaseVersion: null,
        description: null,
        active: true,
        wfmType: {
          wfmTypeId: adapters.wfmTypeId,
        },
        adapterType: {
          adapterTypeId: adapters.adapterTypeId,
        },
        organization: {
          organizationId: null,
        },
        productVariants: [],
      },
      rules: {
        name: { required },
        releaseName: { required },
        releaseDate: { required },
        releaseVersion: { required },
        description: { required },
      },
      validate: null,
    };
  },
  created() {
    if (this.productEdit) {
      this.product = this.clone(this.productEdit);
      this.product.releaseDate = this.formatDate(this.product.releaseDate);
    }
  },
  methods: {
    async addProduct() {
      this.toast.showToast = false;
      const body = this.clone(this.product);
      delete body.productId;
      body.organization.organizationId = this.organizationId;
      let newDate = body.releaseDate;
      if (body.releaseDate) {
        newDate = body.releaseDate.concat('T00:00:00Z');
      }
      body.releaseDate = newDate;
      this.$http
        .post(`${this.config.VUE_APP_ENV_URL}/c5server/v1/product/`, body)
        .then(this.toastSuccess('Product added successfully!'))
        .catch((error) => this.toastError(error, 'Unable to add product.'));
    },
    async updateProduct() {
      this.toast.showToast = false;
      const body = this.clone(this.product);
      const newDate = body.releaseDate.concat('T12:00:00Z');
      body.releaseDate = newDate;
      this.$http
        .put(`${this.config.VUE_APP_ENV_URL}/c5server/v1/product/`, body)
        .then(this.toastSuccess('Product updated successfully!'))
        .catch((error) => this.toastError(error, 'Unable to update product.'));
    },
    async deleteProduct(id) {
      this.toast.showToast = false;
      this.$http
        .delete(`${this.config.VUE_APP_ENV_URL}/c5server/v1/product/${id}`)
        .then(this.toastSuccess('Product deleted successfully!'))
        .catch((error) => this.toastError(error, 'Unable to delete product.'));
    },
    async handleSubmit(apiCall) {
      const v$ = useVuelidate(this.rules, this.product);
      const result = await v$.value.$validate();
      this.validate = v$;
      if (result) {
        apiCall();
      }
    },
    handleClose() {
      this.$emit('refresh');
      this.$emit('update', false);
    },
  },
  components: {
    ToastSubmit,
    DialogWrapper,
    InputErrorWrapper,
    AddEditDeleteButtons,
  },
};
</script>
