<template>
  <nav
    class="header"
    id="styleguideheader"
    role="navigation">
    <CustomerNotifications
      :notifications="notifications"
      @refresh="getNotifications()"
      ref="modal_customernotifications"/>
    <CustomerProfile
      ref="modal"
     :currentUser="currentUser" />
    <div class="container-fluid">
      <div class="header-panels">
        <div class="header-panel hidden-md-down">
          <a
            class="header__logo"
            href="http://www.cisco.com"
            target="_blank">
            <span class="icon-cisco"></span>
          </a>
          <h1
            class="header__title"
            style="margin-left: 30%">
            <span>
              Customer: {{organization!==null ? this.organization.name : "N/A"}}
             </span>
          </h1>
        </div>
        <div
          class="header-panel header-panel--center base-margin-left base-margin-right hidden-lg-up">
          <a
            class="header__logo"
            href="http://www.cisco.com"
            target="_blank">
            <span class="icon-cisco"></span>
          </a>
        </div>
        <div class="header-panel header-panel--right hidden-md-down">
          <a
            href="#/home"
            class="header-item"
            title="Home"
            ><span class="icon-home"></span>
          </a>
          <a
            href="#/home"
            class="header-item"
            title="Activity"
            @click.prevent="showNotifications()"
            ><span class="icon-alert">{{ notificationsCount }}</span>
          </a>
          <a
            href="#/home"
            class="header-item"
            title="User"
            @click.prevent="showProfile()">
            {{ this.currentUser !== null ? this.currentUser.firstName + " " + this.currentUser.lastName : "N/A" }}
          </a>
          <a
            href="#"
            class="header-item"
            @click="logoutEvent()">
            Logout
          </a>
        </div>
      </div>
    </div>
  </nav>
  <br />
</template>

<script>
import CustomerNotifications from '../views/CustomerNotifications.vue';
import CustomerProfile from './databoxes/inputforms/CustomerProfile.vue';
export default {
  data() {
    return {
      notifications: [],
      notificationsCount: "",
      currentUser: null,
      organization: null,
    };
  },
  async created() {
    await this.getCurrentUser();
    await this.getOrganization();
    await this.getNotifications();
    this.notificationsInverval = setInterval(async() => {
      this.getNotifications();
    }, 30000);
  },
  setup() {
    return {
      logout: () =>
        this.$keycloak.logout({ redirectUri: `${this.config.VUE_APP_REDIRECT_URI}` }),
    };
  },
  methods: {
    showNotifications() {
      this.$refs.modal_customernotifications.handleModal();
    },
    showProfile() {
      this.$refs.modal.handleModal();
    },
    getCurrentUser() {
      this.$http
        .get(
          `${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${this.$keycloak.tokenParsed.organizationId}/user/${this.$keycloak.tokenParsed.sub}`
        )
        .then((response) => (this.currentUser = response.data))
        .catch((error) => console.error(error));
    },
    getOrganization() {
      this.$http
        .get(
          `${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${this.$keycloak.tokenParsed.organizationId}`
        )
        .then((response) => (this.organization = response.data))
        .catch((e) => console.error(e));
    },
    getNotifications() {
      this.$http
        .get(
          `${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${this.$keycloak.tokenParsed.organizationId}/notification`
        )
        .then((response) => {
          if (response.status == 200) {
            this.notifications = response.data;
            this.notificationsCount = this.countUnacknowledgedNotifications();
          } else {
            this.error = `Unable to display notifications. Server responded with "${response.status}"`;
          }
        })
        .catch((e) => {
          this.notifications = [];
          console.error(e);
        });
    },
    countUnacknowledgedNotifications() {
      return this.notifications
        .filter(notification => (notification.acknowledged === false || notification.acknowledged === null)).length;
    },
    logoutEvent() {
      localStorage.setItem('logout-event', `logout${Math.random()})`);
      this.$keycloak.logout({ redirectUri: this.redirectUri });
    },
    unmounted() {
      clearInterval(this.notificationsInverval);
    },
  },
  name: 'CustomerHeaderNav',
  components: {
    CustomerNotifications,
    CustomerProfile,
  },
};
</script>
