<template>
  <v-dialog
    width="50%"
    v-if="show"
    persistent>
    <v-card>
      <v-toolbar
        color="#64bbe3"
        style="color: white">
        <v-toolbar-title style="font-size: medium">{{ header }}</v-toolbar-title>
        <v-toolbar-items>
          <v-btn @click="$parent.handleClose()">
            <div :class="`icon-close icon-size-24`" />
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card
        class="cui"
        width="100%"
        style="padding: 2%">
        <slot />
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    header: String,
  },
};
</script>
