<template>
  <v-card>
    <BoxHeader
      type="Variants"
      :action="
        () => {
          props = null;
          variantEdit = null;
          showModalAddEditVariant = !showModalAddEditVariant;
        }
      "
      @update="searchValue = $event" />
    <EasyTable
      alternating
      buttons-pagination
      :rows-per-page="5"
      :fixed-header="false"
      :headers="variantColumns"
      :hide-rows-per-page="true"
      :items="product.productVariants"
      v-model:search-value="searchValue"
      table-class-name="adaptifytable-table">
      <template #header="header">
        <div class="adaptifytable-header">
          {{ header.text }}
        </div>
      </template>
      <template #empty-message>
        <ErrorLoader
          type="Variants"
          :error="error"
          :loading="loading"
          :data="product.productVariants"
          v-if="loading || error" />
        <AdaptifyAlert
          v-else
          type="info"
          :message="`No Data/Matches Found`" />
      </template>
      <template #item-productVariantId="{ productVariantId }">
        <CiscoButton
          color="blue"
          type="pencil"
          :action="() => openEditVariant(productVariantId)" />
        <CiscoButton
          type="more"
          color="blue"
          :action="() => viewProps(productVariantId)" />
      </template>
      <template #item-required="{ required }">
        <span>
          <label class="switch">
            <input
              v-if="required"
              type="checkbox"
              checked
              disabled />
            <input
              v-else
              type="checkbox"
              disabled />
            <span class="switch__input" />
          </label>
        </span>
      </template>
    </EasyTable>
  </v-card>
  <br />
  <PropsBox
    v-if="showProps"
    :props="props"
    :product="product"
    :refresh="refresh"
    :variantEdit="variantEdit"
    v-model:showProps="showProps" />
  <AddEditVariant
    :product="product"
    @refresh="refresh()"
    :variantEdit="variantEdit"
    v-if="showModalAddEditVariant"
    v-model="showModalAddEditVariant"
    @update="showModalAddEditVariant = $event"
    :showModalAddEditVariant="showModalAddEditVariant" />
</template>

<script>
import PropsBox from './PropsBox.vue';
import BoxHeader from './BoxHeader.vue';
import EasyTable from 'vue3-easy-data-table';
import CiscoButton from '../commons/CiscoButton.vue';
import AdaptifyAlert from '../commons/AdaptifyAlert.vue';
import AddEditVariant from './inputforms/AddEditVariant.vue';
import ErrorLoader from '@/components/commons/ErrorLoader.vue';

export default {
  props: {
    product: Object,
    refresh: Function,
  },
  data() {
    return {
      props: null,
      error: null,
      loading: false,
      searchValue: '',
      showProps: false,
      variantEdit: null,
      showModalAddEditVariant: false,
      variantColumns: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Edit',
          value: 'productVariantId',
        },
        {
          text: 'Description',
          value: 'description',
        },
        {
          text: 'DisplayOrder',
          value: 'lastName',
        },
      ],
    };
  },
  methods: {
    openEditVariant(edit) {
      this.loading = true;
      this.variantEdit = this.product.productVariants.find(
        (v) => v.productVariantId === edit
      );
      this.showModalAddEditVariant = true;
      this.loading = false;
    },
    viewProps(edit) {
      this.variantEdit = this.product.productVariants.find(
        (v) => v.productVariantId === edit
      );
      this.props = this.product.productVariants.find(
        (v) => v.productVariantId === edit
      ).productVariantProps;
      this.showProps = true;
    },
  },
  components: {
    PropsBox,
    BoxHeader,
    EasyTable,
    ErrorLoader,
    CiscoButton,
    AddEditVariant,
    AdaptifyAlert,
  },
};
</script>
