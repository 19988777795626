const config = (() => {
  return {
    VUE_RESET_URL: 'https://auth.c5cisco.com/realms/C5realm/protocol/openid-connect/auth?client_id=c5devclient&redirect_uri=https%3A%2F%2Fc5cisco.com%3A3000%2Fadminhome&response_mode=fragment&response_type=code&scope=openid&nonce=efe44113-e1b4-412e-ba9a-560d75a95006&code_challenge=-zFNN3DKm6zA4lycpbQVQXqnL-Ge2mBgZgGRWYXpE_o&code_challenge_method=S256&kc_action=UPDATE_PASSWORD',
    VUE_APP_ENV_URL: 'https://services.c5cisco.com',
    VUE_APP_REDIRECT_URI: 'https://c5cisco.com',
    ROLE_ADMINISTRATOR: 'CISCO_ADMIN',
    ROLE_CISCO_ADMINISTRATOR: 'CUSTOMER_ADMIN',
    url: 'https://auth.c5cisco.com',
    realm: 'C5realm',
    clientId: 'c5devclient',
  };
})();

export default config;

