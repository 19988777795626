<template>
  <div
    v-bind:class="showConfirm ? 'modal modal--small show' : 'modal modal--small hide'"
    id="modal-small">
    <div class="modal__dialog">
      <div class="modal__content">
        <v-toolbar>
        <v-toolbar-title class="text-size-16">Confirm</v-toolbar-title>
         
      </v-toolbar>
        <div class="modal__body">
            <h6>{{ message }}</h6>
        </div>
        <div class="modal__footer">
            <button
            class="btn"
            v-on:click="this.handleModal(true)">
            Ok
            </button>
            <button
            class="btn"
            v-on:click="this.handleModal(false)">
            Cancel
            </button>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  props: {
    showConfirm: Boolean,
    message: String,
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    handleModal(response) {
      this.$emit('response', response);   
    }
  },
};
</script>
<style scoped>
.v-toolbar {
  color: #64bbe3;
  background-color: white;
}
</style>
