<template>
  <div
    class="modal"
    id="modal-default"
    v-if="open">
    <div
      class="modal__dialog"
      style="position: relative; left: 40%; width: 20%; height: 40%">
      <div class="modal__content">
        <a
          class="text-white modal__close"
          v-on:click="handleModal"
          ><span class="icon-close"
        /></a>
        <div
          class="modal__header"
          style="background-color: #64bbe3">
          <br />
          <div
            class="text-center text-size-18"
            style="color: white">
            <span>User Profile</span>
          </div>
          <hr />
        </div>
        <div class="modal__body">
          <div class="form-group base-margin-bottom">
            <CiscoButton
              type="pencil"
              color="blue"
              label="Edit Profile Information"
              :action="
                () => {
                  handleModal();
                  showModalAddEditUser = true;
                  showChangePsw = false;
                }
              " />
          </div>
          <div class="form-group base-margin-bottom">
            <a :href="config.VUE_RESET_URL"
              ><button
                style="color: #64bbe3"
                class="btn btn--link">
                <span :class="`icon-refresh icon-size-24`" />
                Reset Password
              </button></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <AddEditUser
    :userEdit="currentUser"
    v-if="showModalAddEditUser"
    v-model="showModalAddEditUser"
    @refresh="handleModal()"
    :organizationId="currentUser.organizationId"
    :showModalAddEditUser="showModalAddEditUser"
    @update="showModalAddEditUser = $event" />
</template>

<script>
import AddEditUser from './AddEditUser.vue';
import CiscoButton from '@/components/commons/CiscoButton.vue';

export default {
  props: { currentUser: Object },
  data() {
    return {
      open: false,
      userEdit: null,
      showChangePsw: false,
      showModalAddEditUser: false,
    };
  },
  methods: {
    handleModal() {
      this.open = !this.open;
    },
  },
  components: {
    AddEditUser,
    CiscoButton,
  },
};
</script>
