<template>
  <DialogWrapper
    :show="showModalAddEditAdapter"
    :header="`${adapterEdit ? 'Edit' : 'Add'} Adapter`">
    <AdaptifyOverlay :showOverlay="this.showOverlay" :message="this.overlayMessage"/>
    <ToastSubmit
      :toastData="toast"
      v-if="toast.showToast" />
    <span class="row">
      <span class="col-6">
        <InputErrorWrapper
          property="orderEnvironmentId"
          :validate="validate">
          <div class="form-group__text select">
            <select v-model="adapter.orderEnvironmentId">
              <option
                v-for="orderEnvironment in envs"
                :key="orderEnvironment.id"
                :value="orderEnvironment.id">
                {{ orderEnvironment.description }}
              </option>
            </select>
            <label for="input-type-select">Environment</label>
          </div>
        </InputErrorWrapper>
      </span>
      <span class="col-6">
        <InputErrorWrapper
          property="site"
          :validate="validate">
          <div class="form-group__text">
            <input
              type="text"
              v-model="adapter.site" />
            <label>Site</label>
          </div>
        </InputErrorWrapper>
      </span>
    </span>
    <span class="row">
      <span class="col-6">
        <InputErrorWrapper
          property="product"
          :validate="validate">
          <div class="form-group__text select">
            <select v-model="adapter.product">
              <option
                v-for="product in prods"
                :key="product.productId"
                :value="product">
                {{ product.name }}
              </option>
            </select>
            <label for="input-type-select">Service</label>
          </div>
        </InputErrorWrapper>
      </span>
      <span class="col-6">
        <InputErrorWrapper
          property="quantity"
          :validate="validate">
          <div class="form-group__text">
            <input
              type="number"
              v-model="adapter.quantity" />
            <label>Instances</label>
          </div>
        </InputErrorWrapper>
      </span>
    </span>
    <InputErrorWrapper
      property="instanceNamePrefix"
      :validate="validate">
      <div class="form-group__text">
        <input
          type="text"
          v-model="adapter.instanceNamePrefix" />
        <label>Product Instance Name Prefix</label>
      </div>
    </InputErrorWrapper>
    <span class="row">
      <span class="col-6">
        <InputErrorWrapper
          property="controllerReleaseId"
          :validate="validate">
          <div class="form-group__text select">
            <select v-model="adapter.controllerReleaseId">
              <option
                v-for="release in releases"
                :key="release.controllerReleaseId"
                :value="release.controllerReleaseId">
                {{ release.releaseName }} - {{ release.version }}  
              </option>
            </select>
            <label for="input-type-select">Release</label>
          </div>
        </InputErrorWrapper>
      </span>
    </span>
    <InputErrorWrapper
      property="licenseKey"
      :validate="validate">
      <div class="form-group__text">
        <textarea
          rows="4"
          :readonly="!(adapter.product !== null)"
          v-model="adapter.licenseKey" 
          @input="validateLicense()"/>
        <label>License Key</label>
      </div>
    </InputErrorWrapper>
    <span class="row">
      <span class="col-6">
        <InputErrorWrapper
          property="licenseIssueDate"
          :validate="validate">
          <div class="form-group__text">
            <input
              type="date"
              v-model="adapter.licenseIssueDate" />
            <label for="input-type-date">License Issue Date</label>
          </div>
        </InputErrorWrapper>
      </span>
      <span class="col-6">
        <InputErrorWrapper
          property="licenseExpirationDate"
          :validate="validate">
          <div class="form-group__text">
            <input
              disabled
              type="date"
              v-model="adapter.licenseExpirationDate" />
            <label for="input-type-date">License Expiration Date</label>
          </div>
        </InputErrorWrapper>
      </span>
    </span>
    <div v-if="showUpdateButton">
      <AddEditDeleteButtons
        type="Adapter"
        :add="() => handleSubmit(addAdapter)"
        :dataEdit="adapterEdit"
        :update="() => {}" />
    </div>
    <div v-if="!showUpdateButton">
      <div
      class="loading-dots"
      aria-label="Loading, please wait...">
      <span
        v-for="index in 5"
        :key="index" />
      </div>
    </div>
  </DialogWrapper>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import ToastSubmit from './ToastSubmit.vue';
import AdaptifyOverlay from '@/components/commons/AdaptifyOverlay.vue';
import DialogWrapper from './DialogWrapper.vue';
import { required } from '@vuelidate/validators';
import products from '../../../assets/products.json';
import InputErrorWrapper from './InputErrorWrapper.vue';
import AddEditDeleteButtons from './AddEditDeleteButtons.vue';

export default {
  props: {
    adapterEdit: Object,
    organizationId: String,
    showModalAddEditAdapter: Boolean,
  },
  data() {
    return {
      prods: null,
      showUpdateButton: true,
      releases: [],
      showOverlay: false,
      overlayMessage: false,
      adapter: {
        organizationId: null,
        site: null,
        orderEnvironmentId: null,
        licenseKey: null,
        licenseExpirationDate: null,
        licenseIssueDate: null,
        quantity: null,
        product: null,
        instanceNamePrefix: null,
        controllerReleaseId: null
      },
      rules: {
        site: { required },
        orderEnvironmentId: { required },
        licenseKey: { required },
        licenseExpirationDate: {},
        licenseIssueDate: { required },
        quantity: { required },
        product: { required },
        instanceNamePrefix: { required },
        controllerReleaseId: { required }
      },
      validate: null,
      envs: [],
      productsId: products.organizationId,
    };
  },
  async created() {
    this.getEnvs();
    this.getProds();
    this.getReleases();
    if (this.adapterEdit) {
      this.adapter = this.adapterEdit;
    }
  },
  methods: {
    async validateLicense() {
      this.toast.showToast = false;
      this.showOverlay = true;
      this.overlayMessage = "validating license";
      if (this.adapter.product !== undefined && this.adapter.instanceNamePrefix !== undefined) {
        const body = {
          license: this.adapter.licenseKey,
          productId: this.adapter.product.productId,
          productTypeName: this.adapter.product.adapterType.adapterTypeName,
        };
        this.$http
          .post(
            `${this.config.VUE_APP_ENV_URL}/c5serverlicense/v1/organization/${this.organizationId}/license/validate`,
            body
          )
          .then((response) => {
            if (response.data.valid) {
              this.adapter.licenseExpirationDate = this.formatDate(
                response.data.expiration
              );
              this.showOverlay = false;
            } else {
              this.toast.error = true;
              this.toast.showToast = true;
              this.showOverlay = false;
              this.toast.message = `Unable to validate license. ${response.data.message}`;
            }
          })
          .catch((error) => {
            this.toastError(error, 'Unable to validate license.')
            this.showOverlay = false;
          });
      }
    },    
    addAdapter() {
      this.toast.showToast = false;
      if (this.adapter.licenseExpirationDate) {
        const body = this.clone(this.adapter);
        body.productId = body.product.productId;
        body.organizationId = this.organizationId;
        body.orderItems = [
          {
            quantity: body.quantity,
            productId: body.productId,
            instanceNamePrefix: body.instanceNamePrefix,
          },
        ];
        body.licenseExpirationDate = body.licenseIssueDate;
        ['quantity', 'productId', 'instanceNamePrefix', 'product'].forEach(
          (field) => delete body[field]
        );
        ['licenseIssueDate', 'licenseExpirationDate'].forEach(
          (field) => (body[field] = body[field].concat('T00:00:00Z'))
        );
        this.showUpdateButton = false;
        this.$http
          .post(`${this.config.VUE_APP_ENV_URL}/c5server/v1/order`, body)
          .then(response => {
            this.showUpdateButton = true;
            this.toastSuccess('Adapter added successfully!');
          })
          .catch(error => {
            this.showUpdateButton = true;
            this.toastError(error.response.data || error, 'Unable to add adapter');
          });
      }
    },
    getProds() {
      this.showOverlay = true;
      this.overlayMessage = "getting info";
      this.$http
        .get(`${this.config.VUE_APP_ENV_URL}/c5server/v1/product`, {
          headers: {
            'c5-organizationId': this.productId,
          },
        })
        .then((response) => {
          this.showOverlay = false;
          this.prods = response.data;
        })
        .catch((error) => {
          this.toastError(error, 'Unable to fetch product options.')
          this.showOverlay = false;
        });
    },
    getReleases() {
      this.$http
        .get(`${this.config.VUE_APP_ENV_URL}/c5server/v1/controller/release`)
        .then((response) => {
          this.releases = response.data
        })
        .catch((error) => this.toastError(error, 'Unable to fetch product options.'));
    },
    getEnvs() {
      this.$http
        .get(`${this.config.VUE_APP_ENV_URL}/c5server/v1/orderenvironment`)
        .then((response) => (this.envs = response.data))
        .catch((error) => this.toastError(error, 'Unable to fetch envrionments'));
    },
    async handleSubmit(apiCall) {
      const validator = useVuelidate(this.rules, this.adapter);
      const result = await validator.value.$validate();
      this.validate = validator;
      if (result) {
        apiCall();
      }
    },
    handleClose() {
      this.$emit('refresh');
      this.$emit('update', false);
      this.showOverlay = false;
    },
  },
  components: {
    ToastSubmit,
    DialogWrapper,
    InputErrorWrapper,
    AddEditDeleteButtons,
    AdaptifyOverlay,
  },
};
</script>
