<template>
  <DialogWrapper
    :show="showModalAddEditContact"
    :header="`${contactEdit ? 'Edit' : 'Add'} Contact`">
    <ToastSubmit
      :toastData="toast"
      v-if="toast.showToast" />
    <span class="row">
      <span class="col-6">
        <InputErrorWrapper
          property="lastName"
          :validate="validate">
          <div class="form-group__text">
            <input
              type="text"
              v-model="contact.lastName" />
            <label>Last Name</label>
          </div>
        </InputErrorWrapper>
      </span>
      <span class="col-6">
        <InputErrorWrapper
          property="firstName"
          :validate="validate">
          <div class="form-group__text">
            <input
              type="text"
              v-model="contact.firstName" />
            <label>First Name</label>
          </div>
        </InputErrorWrapper>
      </span>
    </span>
    <span class="row">
      <span class="col-10">
        <InputErrorWrapper
          property="phoneNumber"
          :validate="validate">
          <div class="form-group__text">
            <input
              type="tel"
              v-model="contact.phoneNumber" />
            <label>Phone Number</label>
          </div>
          <small>Enter the phone number in international format, including the country code, area code, and local number. For example: +1 (415) 555‑0132</small>
        </InputErrorWrapper>
      </span>
      <span class="col-2">
        <div class="subheader">Active</div>
        <label class="switch">
          <input
            type="checkbox"
            v-model="contact.active" />
          <span class="switch__input" />
        </label>
      </span>
    </span>
    <InputErrorWrapper
      property="address"
      :validate="validate">
      <div class="form-group__text">
        <input
          type="text"
          v-model="contact.address" />
        <label>Address</label>
      </div>
    </InputErrorWrapper>
    <InputErrorWrapper
      property="emailAddress"
      :validate="validate">
      <div class="form-group__text">
        <input
          type="text"
          v-model="contact.emailAddress" />
        <label>Email Address</label>
      </div>
    </InputErrorWrapper>
    <span class="row">
      <span class="col-4">
        <InputErrorWrapper
          property="city"
          :validate="validate">
          <div class="form-group__text">
            <input
              type="text"
              v-model="contact.city" />
            <label for="firstname">City</label>
          </div>
        </InputErrorWrapper>
      </span>
      <span class="col-4">
        <InputErrorWrapper
          property="zipCode"
          :validate="validate">
          <div class="form-group__text">
            <input
              type="text"
              v-model="contact.zipCode" />
            <label>ZipCode</label>
          </div>
        </InputErrorWrapper>
      </span>
      <span class="col-4">
        <InputErrorWrapper
          property="state"
          :validate="validate">
          <div class="form-group__text">
            <input
              type="text"
              v-model="contact.state" />
            <label>State</label>
          </div>
        </InputErrorWrapper>
      </span>
    </span>
    <AddEditDeleteButtons
      type="Contact"
      :add="() => handleSubmit(addEditContact)"
      :dataEdit="contactEdit"
      :update="() => handleSubmit(() => addEditContact(contactEdit.contactId))"
      :remove="() => handleSubmit(() => deleteContact(contactEdit.contactId))" />
  </DialogWrapper>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import ToastSubmit from './ToastSubmit.vue';
import DialogWrapper from './DialogWrapper.vue';
import InputErrorWrapper from './InputErrorWrapper.vue';
import AddEditDeleteButtons from './AddEditDeleteButtons.vue';
import { required, email, alpha } from '@vuelidate/validators';

export default {
  props: {
    customer: Object,
    refresh: Function,
    contactEdit: Object,
    showModalAddEditContact: Boolean,
  },
  data() {
    return {
      contact: {
        firstName: null,
        lastName: null,
        phoneNumber: null,
        address: null,
        city: null,
        state: null,
        zipCode: null,
        active: true,
        emailAddress: null,
      },
      rules: {
        firstName: { required, alpha },
        lastName: { required, alpha },
        phoneNumber: { required },
        address: { required },
        city: { required },
        state: { required },
        zipCode: { required },
        emailAddress: { required, email },
      },
      validate: null,
    };
  },
  async created() {
    if (this.contactEdit !== null) {
      this.contact = this.clone(this.contactEdit);
    }
  },
  methods: {
    addEditContact(id) {
      this.toast.showToast = false;
      const body = this.clone(this.customer);
      if (id) {
        const oldContact = body.contacts.find((contact) => contact.contactId === id);
        const index = body.contacts.indexOf(oldContact);
        body.contacts.splice(index, 1, this.contact);
      } else {
        body.contacts.push(this.contact);
      }
      this.$http
        .put(`${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/`, body)
        .then(this.toastSuccess('Contacts updated successfully!'))
        .catch((error) => this.toastError(error, 'Unable to update contacts.'));
    },
    deleteContact(id) {
      this.toast.showToast = false;
      const body = this.clone(this.customer);
      const deleteContact = body.contacts.find((contact) => contact.contactId === id);
      const index = body.contacts.indexOf(deleteContact);
      body.contacts.splice(index, 1);
      this.$http
        .put(`${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/`, body)
        .then(this.toastSuccess('Contacts updated successfully!'))
        .catch((error) => this.toastError(error, 'Unable to update contacts.'));
    },
    async handleSubmit(apiCall) {
      const v$ = useVuelidate(this.rules, this.contact);
      const result = await v$.value.$validate();
      this.validate = v$;
      if (result) {
        apiCall();
      }
    },
    handleClose() {
      this.$emit('refresh');
      this.$emit('update', false);
    },
  },
  components: {
    ToastSubmit,
    DialogWrapper,
    InputErrorWrapper,
    AddEditDeleteButtons,
  },
};
</script>
