<template>
  <v-dialog
    width="80%"
    v-if="showDialog"
    persistent
    scrollable
  >
    <v-card width="80%" class="form-group base-margin-bottom">
      <v-toolbar>
        <v-toolbar-title class="text-size-24">Metrics data</v-toolbar-title>
        <v-toolbar-items>
          <v-btn @click="this.handleClose()">
            <div :class="`icon-close icon-size-24`" />
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
        <v-tabs
          v-model="tab"
          next-icon="icon-chevron-right"
          prev-icon="icon-chevron-left">
          <v-tab v-if="typeTmp === 'hda'"
              class="v-tab-edit"
              :key="0">
              Reports
          </v-tab>
          <v-tab v-if="typeTmp === 'rta'"
              class="v-tab-edit"
              :key="1">
              CTI Servers
          </v-tab>
          <v-tab v-if="typeTmp === 'rta'"
              class="v-tab-edit"
              :key="2">
              WFM
          </v-tab>
          <v-tab
              class="v-tab-edit"
              :key="3">
              Errors
          </v-tab>
        </v-tabs>
          <v-card-text>
            <v-window v-model="tab" continuous>
              <v-window-item class="text-size-24" v-if="typeTmp === 'hda'" :key="0">
                <EasyTable
                  alternating
                  :items="metricsData.reports"
                  buttons-pagination
                  :rows-per-page="5"
                  :fixed-header="false"
                  :headers="columnsReports"
                  :hide-rows-per-page="true"
                  v-model:search-value="searchValue"
                  table-class-name="adaptifytable-table"/>
              </v-window-item>
              <v-window-item class="text-size-24" v-if="typeTmp === 'rta'" :key="1">
                <EasyTable
                  alternating
                  :items="metricsData.ctiServers"
                  buttons-pagination
                  :rows-per-page="5"
                  :fixed-header="false"
                  :headers="columnsCtiServers"
                  :hide-rows-per-page="true"
                  v-model:search-value="searchValue"
                  table-class-name="adaptifytable-table"/>
              </v-window-item>
              <v-window-item class="text-size-24" v-if="typeTmp === 'rta'" :key="2">
                <EasyTable
                  alternating
                  :items="metricsData.wfmConnections"
                  buttons-pagination
                  :rows-per-page="5"
                  :fixed-header="false"
                  :headers="columnsWfmConnections"
                  :hide-rows-per-page="true"
                  table-class-name="adaptifytable-table"/>
              </v-window-item>
              <v-window-item class="text-size-24" :key="3">
                <EasyTable
                  alternating
                  :items="metricsData.errors"
                  buttons-pagination
                  :rows-per-page="5"
                  :fixed-header="false"
                  :headers="columnsErrors"
                  :hide-rows-per-page="true"
                  v-model:search-value="searchValue"
                  table-class-name="adaptifytable-table"/>
              </v-window-item>
            </v-window>
          </v-card-text>
     </v-card>
  </v-dialog>
</template>

<script>
import { toRef } from 'vue';
import EasyTable from 'vue3-easy-data-table';

export default {
  props: {
    showDialog: Boolean,
    metricsData: Array,
    type: String,
  },
  data() {
    return {
      tab: null,
      columnsErrors: [
        {
          text: "Level",
          value: "level",
        },
        {
          text: "Exception",
          value: "exception",
        },
        {
          text: "Message",
          value: "message",
        },
      ],
      columnsReports: [
        {
          text: "Report name",
          value: "reportName",
        },
        {
          text: "Report Generate Count",
          value: "reportGeneratedCount",
        },
        {
          text: "Errors",
          value: "errors",
        },
      ],
      columnsCtiServers: [
        {
          text: "Pair Id",
          value: "ctiServerPairId",
        },
        {
          text: "Connection Status",
          value: "ctiServerConnectionStatus",
        },
        {
          text: "Active host",
          value: "activeHost",
        },
        {
          text: "Host A",
          value: "ctiServerHostA",
        },
        {
          text: "Port A",
          value: "ctiServerPortA",
        },
        {
          text: "Host B",
          value: "ctiServerHostB",
        },
        {
          text: "port B",
          value: "ctiServerPortB",
        },
      ],
      columnsWfmConnections: [
        {
          text: "Type",
          value: "wfmType",
        },
      ]
    }
  },
  setup(props) {
    const metricsDataTmp = toRef(props, 'metricsData');
    const typeTmp = toRef(props, 'type');
    return {
      metricsDataTmp,
      typeTmp
    };
  },
  name: "MetricsDialog",
  methods: {
    handleClose() {
      this.$emit('update', false);
    },
  },
  components: {
    EasyTable,
  },
}
</script>
 
<style scoped>
.v-tab-edit {
  height: 20px;
  color: #65bbe3;
  background-color: white;
  font-size: medium; 
  border: 2rem;
}
.v-toolbar {
  color: white;
  background-color: #64bbe3;
}
.v-tab-edit:hover {
  height: 40px;
  color: #65bbe3;
  background-color: white;
}
</style>
