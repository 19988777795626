<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <AdminNav
    v-bind:organization="org"
    v-bind:currentUser="currentUser" />
  <div class="content --alt">
    <div className="container-fluid">
      <ProductBox v-bind:organizationId="organizationId" />
    </div>
  </div>
</template>

<script>
import AdminNav from '../components/AdminHeader.vue';
import ProductBox from '@/components/databoxes/ProductBox.vue';

export default {
  data() {
    return {
      loading: false,
      currentUser: null,
      organizationId: null,
    };
  },
  async created() {
    this.organizationId = this.$keycloak.tokenParsed.organizationId;
    this.getCurrentUser(
      this.$keycloak.tokenParsed.organizationId,
      this.$keycloak.tokenParsed.sub
    );
  },
  methods: {
    async getCurrentUser(organizationId, userId) {
      await this.$http
        .get(
          `${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${organizationId}/user/${userId}`
        )
        .then((response) => (this.currentUser = response.data))
        .catch((error) => console.error(error));
    },
  },
  components: {
    AdminNav,
    ProductBox,
  },
};
</script>
