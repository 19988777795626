<template>
  <div
    :class="`form-group ${
      validate && validate[property].$errors.length ? 'form-group--error' : ''
    } base-margin-bottom`">
    <slot />
    <FormErrors
      :property="property"
      :validate="validate" />
  </div>
</template>

<script>
import FormErrors from './FormErrors.vue';

export default {
  props: {
    property: String,
    validate: Object,
  },
  components: { FormErrors },
};
</script>
