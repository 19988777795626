<template>
  <div v-if="type === 'confirm'">
    <div
      v-bind:class="open ? 'modal modal--small show' : 'modal modal--small hide'"
      id="modal-small">
      <div class="modal__dialog">
        <div class="modal__content">
          <a
            class="modal__close"
            onclick="handleModal('modal-small')"
            ><span class="icon-close"></span
          ></a>
          <div class="modal__header">
            <div class="modal__title">Confirm</div>
          </div>
          <div class="modal__body">
            <p class="subtitle">{{ message }}</p>
          </div>
          <div class="modal__footer">
            <button
              class="btn"
              onclick="handleModal('modal-small')">
              Ok
            </button>
            <button
              class="btn"
              v-on:click="this.handleModal()">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="type === 'info'" :class="{'flash': this.animate}">
    <div class="alert alert--info">
      <div class="alert__icon icon-info-outline" />
      <div class="alert__message">
        {{ message }}
      </div>
    </div>
  </div>
  <div v-if="type === 'info-solid'" :class="{'flash': this.animate}">
    <div class="alert alert--info">
      <div class="alert__icon icon-check-outline" />
      <div class="alert__message text-bold capitalize ">
        {{ message }}
      </div>
    </div>
  </div>
  <div v-if="type === 'warning'" >
    <div :class="`alert alert--${type}`">
      <div :class="`alert__icon icon-${type}-outline`" />
      <div class="alert__message">
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: String,
    message: String,
    animate: Boolean,
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    handleModal: () => (this.open = !this.open),
  },
};
</script>
<style scoped>
.capitalize{
    text-transform: capitalize;
}

div .flash{
  animation-name: flash;
  animation-duration: 1s;
  animation-iteration-count: 1;
}

@keyframes flash {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: #65bbe3;
  }
  100% {
    background-color: transparent;
  }
}
</style>
