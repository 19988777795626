<template>
  <div class="modal" id="modal-default" v-if="open">
    <div class="modal__dialog" style="position: relative;">
      <div class="modal__content">
        <div class="modal__body">
          <a class="modal__close" v-on:click="handleModal">
            <span class="icon-close"></span>
          </a>
          <div class="content content--alt">
            <div class="col-15 col-lg-9 col-xl-11">
              <div class="section">
                <div class="activities-row">
                  <div class="activities-panel panel panel--loose panel--raised">
                    <div class="activity-log-title">
                      Activity Log
                      <br>
                      <input type="text" class="activities-search-bar" v-model="searchQuery" placeholder="Search" />
                    </div>
                    <hr />
                    <div class="timeline">
                      <div
                        class="timeline__item"
                        v-for="item in filteredTimeline"
                        :key="item.activityLogId"
                      >
                        <div class="timeline__content">
                          <div class="activity-date-text">
                            {{ item.activityTime.replace(/T|Z/g, " ") }}
                          </div>
                          <p class="notification-detail">{{ item.activityDetail }}</p>
                        </div>
                      </div>
                      <div class="pagination-controls">
                        <button
                          @click="prevPage"
                          :disabled="currentPage === 1 || totalPages === 0"
                          class="nav-button"
                          :class="{ disabled: currentPage === 1 || totalPages === 0 }"
                        >
                          Previous
                        </button>

                        <div class="page-info">
                          Page {{ currentPage }} of {{ totalPages }}
                        </div>

                        <button
                          @click="nextPage"
                          :disabled="currentPage === totalPages || totalPages === 0"
                          class="nav-button"
                          :class="{ disabled: currentPage === totalPages || totalPages === 0 }"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import '../assets/css/notificationstimeline.css';

export default {
  name: 'NotificationsPage',
  data() {
    return {
      open: false,
      searchQuery: '',
      timeline: [],
      currentPage: 1,
      itemsPerPage: 10,
    };
  },
  async created() {
    try {
      const response = await axios.get(`${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${this.$keycloak.tokenParsed.organizationId}/activityLog`);
      if (response.status === 200) {
        this.timeline = response.data;
        this.timeline.sort((a, b) => new Date(b.activityTime) - new Date(a.activityTime));
      }
    } catch (error) {
      console.error('Could not fetch timeline', error);
    }
  },
  computed: {
    filteredTimeline() {
      const filteredTimeline = this.filterTimelineByQuery();
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return filteredTimeline.slice(start, end);
    },
    totalPages() {
      const filteredTimeline = this.filterTimelineByQuery();
      return Math.max(1, Math.ceil(filteredTimeline.length / this.itemsPerPage));
    },
  },
  methods: {
    handleModal() {
      this.open = !this.open;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      } else {
        this.currentPage = this.totalPages;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      } else {
        this.currentPage = this.totalPages === 1 && this.timeline.length === 0 ? 0 : 1;
      }
    },
    filterTimelineByQuery() {
      const query = this.searchQuery.toLowerCase();
      return this.timeline.filter(item =>
        (item.activityDetail && item.activityDetail.toLowerCase().includes(query))
        || (item.activityTime && item.activityTime.toLowerCase().includes(query))
      );
    },
  }
};
</script>
