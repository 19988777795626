<template>
  <v-dialog
    width="80%"
    v-if="showMetrics"
    persistent
    scrollable
  >
    <v-card width="90%" class="form-group base-margin-bottom">
      <v-toolbar>
        <v-toolbar-title class="text-size-24">{{adapterNameTmp}} Metrics</v-toolbar-title>
        <v-toolbar-items>
          <v-btn @click="this.handleClose()">
            <div :class="`icon-close icon-size-24`" />
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <MetricsDialog v-model="showMetricsInfo" @update="showMetricsInfo = $event" :showDialog="showMetricsInfo" :metricsData="metricsData" :type="type">
      </MetricsDialog>
      <EasyTable
        alternating
        :items="this.metricsTmp"
        buttons-pagination
        :rows-per-page="5"
        :fixed-header="false"
        :headers="columns"
        :hide-rows-per-page="true"
        table-class-name="adaptifytable-table" >
          <template #item-metricsrow="{
            productInstanceMetricsId}" >
          <div class="col-md-6">
            <a href="javascript:void(0)" @click="showMetricsDialog(productInstanceMetricsId)">Metrics</a>
          </div>
        </template>
     </EasyTable>
    </v-card>
</v-dialog>
</template>

<script>
import { toRef } from 'vue';
import MetricsDialog from './MetricsDialog.vue'
import EasyTable from 'vue3-easy-data-table';

export default {
  props: {
    metrics: Array,
    adapterName: String,
    showMetrics: Boolean,
  },
  setup(props) {
    const metricsTmp = toRef(props, 'metrics');
    const adapterNameTmp = toRef(props, 'adapterName');
    return {
      metricsTmp,
      adapterNameTmp
    };
  },
  name: "MetricsLogs",
  methods: {
    showMetricsDialog(productInstanceMetricsId) {
      const metricTmp =  this.metricsTmp[this.metricsTmp.findIndex(metric => metric.productInstanceMetricsId === productInstanceMetricsId)].productInstanceMetricsData;
      if (metricTmp !== null) {
        if (metricTmp.productType === 'rta') {
          this.metricsData = metricTmp.metrics.rta;
          this.type = "rta";
        } else {
          this.metricsData = metricTmp.metrics.hda;
          this.type = "hda";
        }
      } 
      this.showMetricsInfo = true;
    },
    handleClose() {
      this.toastSuccess();
      this.$emit('refresh');
      this.$emit('update', false);
    },
  },
  data() {
    return {
      columns: [
        {
          text: "Date",
          value: "createdDate",
        },
        {
          text: "Status",
          value: "productInstanceMetricsStatus.description",
        },
        {
          text: "Data",
          value: "metricsrow",
        },
      ],
      metricIndex: 0,
      type: null,
      metricsData: [],
      showMetricsInfo: false,
    };
  },
  components: { MetricsDialog, EasyTable },
};
</script>
<style scoped>
.v-tab-edit {
  height: 20px;
  color: #65bbe3;
  background-color: white;
  font-size: medium; 
  border: 2rem;
}
.v-toolbar {
  color: white;
  background-color: #64bbe3;
}
.v-tab-edit:hover {
  height: 40px;
  color: #65bbe3;
  background-color: white;
}
</style>
