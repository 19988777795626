<template>
  <div class="loading-dots" aria-label="Loading, please wait...">
    {{ msg }}<span v-for="index in 5" :key="index" />
  </div>
</template>
<script>
export default {
  props: {
    msg: String,
  },
};
</script>
