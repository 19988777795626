<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <AdminNav v-bind:currentUser="currentUser" />
  <div className="container-fluid">
    <ErrorLoader
      v-bind:loading="loading"
      v-bind:data="product"
      v-bind:type="`Product`"
      v-bind:error="error" />
    <span
      className="row"
      v-if="!loading && product">
      <h1 class="col-6">
        {{ product.name }}
      </h1>
      <span class="col-6 flex flex-right">
        <CiscoButton
          color="blue"
          type="pencil"
          label="Edit Product"
          :action="
            () => {
              productEdit = product;
              showModalAddEditProduct = !showModalAddEditProduct;
            }
          " />
      </span>
    </span>
    <div
      class="row"
      v-if="!loading && product">
      <div class="col-3">
        <span class="labels">Release Name</span>
        <div>{{ product.releaseName }}</div>
        <span class="labels">Release Version</span>
        <div>{{ product.releaseVersion }}</div>
      </div>
      <div class="col-3">
        <span class="labels">Release Date</span>
        <div>{{ product.releaseDate }}</div>
        <span class="labels">Created Date</span>
        <div>{{ product.createdDate }}</div>
      </div>
      <div class="col-3">
        <span class="labels">Updated Date</span>
        <div>{{ product.updatedDate }}</div>
        <span class="labels">Active</span>
        <div>{{ product.active.toString() }}</div>
      </div>
      <div class="col-3">
        <span class="labels">Description</span>
        <div>{{ product.description }}</div>
      </div>
    </div>
    <br />
    <VariantsBox
      v-if="!loading && product"
      :product="product"
      :refresh="getProduct" />
    <AddEditProduct
      :productEdit="product"
      v-if="showModalAddEditProduct"
      @refresh="getProduct()"
      v-model="showModalAddEditProduct"
      :showModalAddEditProduct="showModalAddEditProduct"
      @update="showModalAddEditProduct = $event" />
  </div>
</template>

<script>
import AdminNav from '../components/AdminHeader.vue';
import ErrorLoader from '@/components/commons/ErrorLoader.vue';
import CiscoButton from '@/components/commons/CiscoButton.vue';
import VariantsBox from '@/components/databoxes/VariantsBox.vue';
import AddEditProduct from '@/components/databoxes/inputforms/AddEditProduct.vue';

export default {
  data() {
    return {
      error: null,
      product: null,
      loading: false,
      currentUser: null,
      productEdit: null,
      organizationId: null,
      showModalAddEditProduct: false,
    };
  },
  async created() {
    this.organizationId = this.$keycloak.tokenParsed.organizationId;
    this.getProduct();
  },
  methods: {
    async getProduct() {
      this.loading = true;
      await this.$http
        .get(
          `${this.config.VUE_APP_ENV_URL}/c5server/v1/product/${this.$route.params.id}`
        )
        .then((response) => (this.product = response.data))
        .catch((e) => {
          this.error = `Unable to display product. Server responded with "${e.message}"`;
          console.error(e);
        });
      this.loading = false;
    },
  },
  components: {
    AdminNav,
    VariantsBox,
    ErrorLoader,
    CiscoButton,
    AddEditProduct,
  },
};
</script>

<style>
.labels {
  font-weight: bold;
}
</style>
