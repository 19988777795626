<template>
  <v-dialog
    width="50%"
    :height="`${this.alertHeight}%`"
    v-if="showDialog"
    persistent
    scrollable
  >
    <v-card width="50%"  class="form-group base-margin-bottom">
      <v-toolbar>
        <v-toolbar-title class="text-size-24">Info</v-toolbar-title>
        <v-toolbar-items>
          <v-btn @click="this.handleClose()">
            <div :class="`icon-close icon-size-24`" />
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      
      <v-card-text>
        <div class="row">
          <div class="col-lg-12">
            <div class="form-group">
              <br/>
              <h5 class="text-warning">Please check the following errors:</h5>
              <br/>
              <ul>
                <li v-for="message,index in messages" :key="index">
                  {{ message }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { toRef } from 'vue';

export default {
  props: {
    showDialog: Boolean,
    messages: Array,
    height: String
  },
  data() {
  },
  setup(props) {
    const messagesRows = toRef(props, 'messages');
    const alertHeight = toRef(props, 'height');
    return {
      messagesRows,
      alertHeight
    };
  },
  name: "AdaptifyDialogAlert",
  methods: {
    handleClose() {
      this.$emit('update', false);
    },
  }
}
</script>
<style scoped>
.v-toolbar {
  color: white;
  background-color: #64bbe3;
}
</style>
