<template>
  <span v-if="validate">
    <span
      v-for="error in validate[property].$errors"
      :key="error.$uid"
      style="color: red"
      >{{ error.$message }}</span
    >
  </span>
</template>

<script>
export default {
  props: {
    property: String,
    validate: Object,
  },
};
</script>
