<template>
  <v-card-title>
    <span class="row boxheader">
      <h2 className="col-3">{{ variant ? `${variant} ` : `` }}{{ type }}</h2>
      <span class="col-9 flex flex-right">
        <div class="form-group form-group--inline text">
          <div class="form-group__text">
            <input
              type="search"
              v-model="searchValue"
              @keyup="$emit('update', searchValue)" />
            <label>Search</label>
          </div>
        </div>
        <CiscoButton
          v-if="action"
          :color="disableButton ? 'gray': 'blue'"
          :action="action"
          :label="`Add ${type}`"
          :isDisabled=disableButton
          :type="type === `Users` ? `add-contact` : `add-outline`" />
        <CiscoButton
          type="close"
          color="blue"
          :action="close"
          v-if="type === `Props`" />
      </span>
    </span>
  </v-card-title>
</template>

<script>
import CiscoButton from '../commons/CiscoButton.vue';

export default {
  props: {
    type: String,
    close: Function,
    variant: String,
    action: Function,
    disableButton: Boolean,
  },
  data() {
    return {
      searchValue: ""
    }
  },
  components: {
    CiscoButton,
  },
};
</script>

<style>
.boxheader {
  padding: 0.25% 0% 1% 0.25%;
}
</style>
