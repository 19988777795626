<template>
  <body class="cui">
    <v-app>
      <router-view />
    </v-app>
  </body>
</template>

<script>
export default {
  name: 'App',
  components: {},
};
</script>

<style>
@import './assets/css/cui.css';
.v-dialog--scrollable > .v-overlay__content{
    align-items: center;
}
</style>
