<template>
  <button
    :style="`color: ${ handleColor(color)}; ${changeDisabledStyle(isDisabled)}`"
    :disabled= isDisabled
    class="btn btn--link"
    v-on:click="action()">
    <div :class="`icon-${type} icon-size-24`" />
    {{ label }}
  </button>
</template>

<script>
export default {
  props: {
    type: String,
    label: String,
    color: String,
    isDisabled: Boolean,
    action: Function,
  },
  methods: {
    handleColor(color) {
      if (color === 'blue') {
        return '#64bbe3'
      } else if (color === 'red') {
        return 'red'
      } else if (color === 'gray') {
        return '#6C6C6C'
      } else {
        return 'white'
      }
    },
    changeDisabledStyle(isDisabled) {
      if (isDisabled) {
        return 'border: 0px'
      } else {
        return ''
      }
    }
  }
};
</script>
<style scoped>
button:hover {
    opacity: 0.4;
}
</style>
