<template>
  <DialogWrapper
    :show="showModalAddEditVariant"
    :header="`${variantEdit ? 'Edit' : 'Add'} Variant`">
    <ToastSubmit
      :toastData="toast"
      v-if="toast.showToast" />
    <span class="row">
      <span class="col-9">
        <InputErrorWrapper
          property="name"
          :validate="validate">
          <div class="form-group__text">
            <input
              type="text"
              v-model="productVariant.name" />
            <label for="name">Name</label>
          </div>
        </InputErrorWrapper>
      </span>
      <span class="col-3 flex flex-right">
        <div>
          <div class="subheader">Required</div>
          <label class="switch">
            <input
              type="checkbox"
              v-model="productVariant.required" />
            <span class="switch__input" />
          </label>
        </div>
      </span>
    </span>
    <InputErrorWrapper
      property="description"
      :validate="validate">
      <div class="form-group__text">
        <textarea
          rows="4"
          v-model="productVariant.description" />
        <label>Description</label>
      </div>
    </InputErrorWrapper>
    <InputErrorWrapper
      property="displayOrder"
      :validate="validate">
      <div class="form-group__text">
        <input
          type="number"
          v-model="productVariant.displayOrder" />
        <label>Display Order</label>
      </div>
    </InputErrorWrapper>
    <AddEditDeleteButtons
      type="Variant"
      :add="() => handleSubmit(addEditVariant)"
      :dataEdit="variantEdit"
      :update="
        () => handleSubmit(() => addEditVariant(this.productVariant.productVariantId))
      "
      :remove="
        () => handleSubmit(() => deleteVariant(this.productVariant.productVariantId))
      " />
  </DialogWrapper>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import ToastSubmit from './ToastSubmit.vue';
import DialogWrapper from './DialogWrapper.vue';
import { required } from '@vuelidate/validators';
import InputErrorWrapper from './InputErrorWrapper.vue';
import AddEditDeleteButtons from './AddEditDeleteButtons.vue';

export default {
  props: {
    product: Object,
    refresh: Function,
    variantEdit: Object,
    showModalAddEditVariant: Boolean,
  },
  data() {
    return {
      productEdit: null,
      productVariant: {
        productVariantId: null,
        name: null,
        required: true,
        description: null,
        displayOrder: null,
        productVariantProps: [],
      },
      rules: {
        name: { required },
        description: { required },
        displayOrder: { required },
      },
      validate: null,
    };
  },
  async created() {
    this.productEdit = this.clone(this.product);
    if (this.variantEdit) {
      this.productVariant = this.clone(this.variantEdit);
    }
  },
  methods: {
    async addEditVariant(id) {
      this.loading = true;
      this.showToast = false;
      const body = this.productEdit;
      if (id) {
        const variant = body.productVariants.find((v) => v.productVariantId === id);
        const index = body.productVariants.indexOf(variant);
        body.productVariants.splice(index, 1, this.productVariant);
      } else {
        body.productVariants.push(this.productVariant);
      }
      this.$http
        .put(`${this.config.VUE_APP_ENV_URL}/c5server/v1/product/`, body)
        .then(this.toastSuccess('Variants updated successfully!'))
        .catch((error) => this.toastError(error, 'Unable to update varaints.'));
    },
    async deleteVariant(id) {
      this.loading = true;
      this.showToast = false;
      const body = this.productEdit;
      const variant = body.productVariants.find((v) => v.productVariantId === id);
      const index = body.productVariants.indexOf(variant);
      body.productVariants.splice(index, 1);
      this.$http
        .put(`${this.config.VUE_APP_ENV_URL}/c5server/v1/product/`, body)
        .then(this.toastSuccess('Variants updated successfully!'))
        .catch((error) => this.toastError(error, 'Unable to update varaints.'));
    },
    async handleSubmit(apiCall) {
      const v$ = useVuelidate(this.rules, this.productVariant);
      const result = await v$.value.$validate();
      this.validate = v$;
      if (result) {
        apiCall();
      }
    },
    handleClose() {
      this.$emit('refresh');
      this.$emit('update', false);
    },
  },
  components: {
    ToastSubmit,
    DialogWrapper,
    InputErrorWrapper,
    AddEditDeleteButtons,
  },
};
</script>
