<template>
  <div></div>
</template>

<script>
export default {
  async created() {
    if (this.$keycloak.realmAccess.roles.includes(this.config.ROLE_ADMINISTRATOR)) {
      this.$router.push('/adminhome');
    }
    if (this.$keycloak.realmAccess.roles.includes(this.config.ROLE_CISCO_ADMINISTRATOR)) {
      this.$router.push('/customerhome');
    }
  },
  name: 'HomePage',
};
</script>
