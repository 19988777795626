<template>
  <span>
    <div :class="toastData.error ? `alert alert--danger` : `alert alert--success`">
      <div class="alert__message">
        <span>
          {{ toastData.message }}
        </span>
      </div>
    </div>
  </span>
</template>

<script>
export default {
  props: {
    toastData: Object,
  },
};
</script>
