<template>
  <v-overlay
    :model-value="showOverlay"
    persistent
    class="adaptify-overlay"
   >
     <v-progress-circular
       color="var(--cui-color-midnight)"
       class="v-overlay-content"
       indeterminate
       size="64"
     > {{ message }} 
    </v-progress-circular>
  </v-overlay>
</template>
   
<script>
export default {
  props: {
    showOverlay: Boolean,
    message: String
  },
  data() {
  },
};
</script>
<style scoped>
  .adaptify-overlay >>> .v-overlay__content {
    width: 100%;
    left: 45%;
    top: 40%;
    height: 100%;
  }
  .adaptify-overlay >>> .v-progress-circular__content {
    text-align: center;
  }
</style>
