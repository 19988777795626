import { createApp } from 'vue';
import App from './App.vue';
import config from '../config.js';
import axios from 'axios';
import Keycloak from 'keycloak-js';
import { CookieName } from './auth/KCConfig.js';
import { setCookieValue } from './auth/Functions';
import HomePage from './views/Home.vue';
import AdapterInstances from './views/Instances.vue';
import InstanceConfiguration from './views/Configuration.vue';
import CustomersPage from './views/Customers.vue';
import CustomerPage from './views/Customer.vue';
import ProductsPage from './views/Products.vue';
import ProductPage from './views/Product.vue';
import OrdersPage from './views/Orders.vue';
import AdminHome from './views/AdminHome.vue';
import CustomerHome from './views/CustomerHome.vue';
import NotFoundPage from './views/NotFound.vue';
import EditConfiguration from './components/databoxes/inputforms/EditConfiguration.vue';
import { createRouter, createWebHistory } from 'vue-router';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

axios.interceptors.response.use((response) => {
  if (
    ![200, 201].includes(response.status)
    || (response.data.valid && response.data.valid === false)
  ) {
    return Promise.reject(
      new Error({
        message: `${response.message}`,
      })
    );
  }
  return response;
});
const app = createApp(App);
app.mixin({
  methods: {
    clone(x) {
      return JSON.parse(JSON.stringify(x));
    },
    formatDate(x) {
      const d = new Date(x);
      const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
      const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
      const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
      return `${ye}-${mo}-${da}`;
    },
    toastSuccess(message) {
      this.toast.error = false;
      this.toast.message = message ? message : '';
      this.toast.showToast = message ? true : false;
    },
    toastError(error, message) {
      console.error(error);
      this.toast.error = true;
      this.toast.showToast = true;
      this.toast.message = `${message} ${error.message}`;
    },
  },
  data() {
    return {
      config: config,
      toast: {
        error: false,
        message: null,
        showToast: false,
      },
    };
  },
});
const vuetify = createVuetify({
  components,
  directives,
});
const keycloak = new Keycloak(config);
keycloak
  .init({
    onLoad: 'login-required',
    pkceMethod: 'S256',
    checkLoginIframe: false,
  })
  .then((authenticated) => {
    setCookieValue(CookieName, keycloak.token);
    if (!authenticated) {
      window.location.reload();
    } else {
      window.addEventListener('storage', (event) => {
        if (event.key === 'logout-event') {
          keycloak.logout({ redirectUri: config.VUE_APP_REDIRECT_URI });
        }
      });
    }
    setInterval(() => {
      keycloak
        .updateToken(20)
        .then((refreshed) => {
          if (refreshed) {
            console.log(`Token refreshed${refreshed}`);
            axios.defaults.headers.Authorization = `Bearer ${keycloak.token}`;
            setCookieValue(CookieName, keycloak.token);

          } else {
            console.log(
              `Token not refreshed, valid for ${Math.round(
                keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000
              )} seconds`
            );
          }
        })
        .catch(() => {
          console.log('Failed to refresh token');
        });
    }, 6000);
    try {
      if (!authenticated) {
        localStorage.setItem('logout-event', `logout${Math.random()})`);
        console.log('Not Authenticated');
        return keycloak.login({ redirectUri: window.location.href });
      } else {
        const routes = [
          {
            path: '/',
            component: HomePage,
            meta: {
              allowedRole: '*',
              authenticated: true,
            },
          },
          {
            path: '/home',
            component: HomePage,
            meta: {
              allowedRole: '*',
              authenticated: true,
            },
          },
          {
            path: '/customerhome',
            component: CustomerHome,
            meta: {
              allowedRole: config.ROLE_CISCO_ADMINISTRATOR,
              authenticated: true,
            },
          },
          {
            path: '/adminhome',
            component: AdminHome,
            meta: {
              allowedRole: config.ROLE_ADMINISTRATOR,
              authenticated: true,
            },
          },
          {
            path: '/instances',
            component: AdapterInstances,
            meta: {
              allowedRole: config.ROLE_ADMINISTRATOR,
              authenticated: true,
            },
          },
          {
            path: '/configuration',
            component: InstanceConfiguration,
            meta: {
              allowedRole: config.ROLE_ADMINISTRATOR,
              authenticated: true,
            },
          },
          {
            path: '/customers',
            component: CustomersPage,
            meta: {
              allowedRole: config.ROLE_ADMINISTRATOR,
              authenticated: true,
            },
          },
          {
            path: '/customer/:id',
            component: CustomerPage,
            meta: {
              allowedRole: config.ROLE_ADMINISTRATOR,
              authenticated: true,
            },
          },
          {
            path: '/customer/:id',
            component: CustomerPage,
          },
          {
            path: '/products',
            component: ProductsPage,
            meta: {
              allowedRole: config.ROLE_ADMINISTRATOR,
              authenticated: true,
            },
          },
          {
            path: '/product/:id',
            component: ProductPage,
            meta: {
              allowedRole: config.ROLE_ADMINISTRATOR,
              authenticated: true,
            },
          },
          {
            path: '/orders',
            component: OrdersPage,
            meta: {
              allowedRole: config.ROLE_ADMINISTRATOR,
              authenticated: true,
            },
          },
          {
            path: '/edit_configuration',
            component: EditConfiguration,
            meta: {
              allowedRole: config.ROLE_ADMINISTRATOR,
              authenticated: true,
            },
          },
          {
            path: '/:pathMatch(.*)*',
            component: NotFoundPage,
            meta: {
              allowedRole: config.ROLE_ADMINISTRATOR,
              authenticated: true,
            },
          },
        ];

        const router = createRouter({
          history: createWebHistory(),
          routes,
        });

        router.beforeEach((to, from, next) => {
          if (authenticated) {
            if (
              keycloak.realmAccess.roles.includes(to.meta.allowedRole)
              || to.meta.allowedRole === '*'
            ) {
              return next();
            } else {
              router.push({
                path: '/',
              });
            }
          } else {
            return next();
          }
        });
        setCookieValue(CookieName, keycloak.token);
        axios.defaults.headers.Authorization = `Bearer ${keycloak.token}`;
        app.displayURL = config.VUE_APP_ENV_URL || process.env.VUE_APP_ENV_URL;
        app.config.globalProperties.$http = axios;
        app.config.globalProperties.$keycloak = keycloak;
        app.use(router).use(vuetify);
        app.mount('#app');
      }
    } catch (error) {
      console.log(`Authenticated failed due to \n${error}`);
    }
  });
