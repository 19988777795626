<template>
  <DialogWrapper
    :show="showModalAddEditCustomer"
    :header="`${customerEdit ? 'Edit' : 'Add'} Customer`">
    <ToastSubmit
      :toastData="toast"
      v-if="toast.showToast" />
    <span class="row">
      <span class="col-10">
        <InputErrorWrapper
          property="name"
          :validate="customerValidate">
          <div class="form-group__text">
            <input
              type="text"
              v-model="customer.name" />
            <label>Name</label>
          </div>
        </InputErrorWrapper>
      </span>
      <span class="col-2">
        <div class="subheader">Active</div>
        <label class="switch">
          <input
            type="checkbox"
            v-model="customer.active" />
          <span class="switch__input" />
        </label>
      </span>
    </span>
    <InputErrorWrapper
      property="description"
      :validate="customerValidate">
      <div class="form-group__text">
        <textarea
          rows="3"
          v-model="customer.description" />
        <label>Description</label>
      </div>
    </InputErrorWrapper>
    <InputErrorWrapper>
      <div class="form-group__text select">
        <select v-model="selectedOption" @change="handleChange">
          <option v-for="option in options" :key="option.id" :value="option.id">
            {{ option.name }}
          </option>
        </select>
        <label for="input-type-select">Timezone</label>
      </div>
    </InputErrorWrapper>
    <div v-if="!customerEdit">
      <h5>Contact</h5>
      <hr />
      <span class="row">
        <span class="col-6">
          <InputErrorWrapper
            property="lastName"
            :validate="contactValidate">
            <div class="form-group__text">
              <input
                type="text"
                v-model="contact.lastName" />
              <label>Last Name</label>
            </div>
          </InputErrorWrapper>
        </span>
        <span class="col-6">
          <InputErrorWrapper
            property="firstName"
            :validate="contactValidate">
            <div class="form-group__text">
              <input
                type="text"
                v-model="contact.firstName" />
              <label>First Name</label>
            </div>
          </InputErrorWrapper>
        </span>
      </span>
      <span class="row">
        <span class="col-10">
          <InputErrorWrapper
            property="phoneNumber"
            :validate="contactValidate">
            <div class="form-group__text">
              <input
                type="tel"
                v-model="contact.phoneNumber" />
              <label>Phone Number</label>
            </div>
            <small>Enter the phone number in international format, including the country code, area code, and local number. For example: +1 (415) 555‑0132</small>
          </InputErrorWrapper>
        </span>
        <span class="col-2">
          <div class="subheader">Active</div>
          <label class="switch">
            <input
              type="checkbox"
              v-model="contact.active" />
            <span class="switch__input" />
          </label>
        </span>
      </span>
      <InputErrorWrapper
        property="address"
        :validate="contactValidate">
        <div class="form-group__text">
          <input
            type="text"
            v-model="contact.address" />
          <label>Address</label>
        </div>
      </InputErrorWrapper>
      <InputErrorWrapper
        property="emailAddress"
        :validate="contactValidate">
        <div class="form-group__text">
          <input
            type="text"
            v-model="contact.emailAddress" />
          <label>Email Address</label>
        </div>
      </InputErrorWrapper>
      <span class="row">
        <span class="col-4">
          <InputErrorWrapper
            property="city"
            :validate="contactValidate">
            <div class="form-group__text">
              <input
                type="text"
                v-model="contact.city" />
              <label for="firstname">City</label>
            </div>
          </InputErrorWrapper>
        </span>
        <span class="col-4">
          <InputErrorWrapper
            property="zipCode"
            :validate="contactValidate">
            <div class="form-group__text">
              <input
                type="text"
                v-model="contact.zipCode" />
              <label>ZipCode</label>
            </div>
          </InputErrorWrapper>
        </span>
        <span class="col-4">
          <InputErrorWrapper
            property="state"
            :validate="contactValidate">
            <div class="form-group__text">
              <input
                type="text"
                v-model="contact.state" />
              <label>State</label>
            </div>
          </InputErrorWrapper>
        </span>
      </span>
    </div>
    <AddEditDeleteButtons
      type="Customer"
      :add="() => handleSubmit(addCustomer)"
      :dataEdit="customerEdit"
      :update="() => handleSubmit(updateCustomer)"
      :remove="() => handleSubmit(() => deleteCustomer(customer.organizationId))" />
  </DialogWrapper>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import ToastSubmit from './ToastSubmit.vue';
import DialogWrapper from './DialogWrapper.vue';
import InputErrorWrapper from './InputErrorWrapper.vue';
import AddEditDeleteButtons from './AddEditDeleteButtons.vue';
import { required, email, alpha, numeric, alphaNum } from '@vuelidate/validators';

export default {
  props: {
    customerEdit: Object,
    showModalAddEditCustomer: Boolean,
  },
  data() {
    return {
      customer: {
        organizationId: null,
        name: null,
        active: true,
        description: null,
        contacts: [],
        timezoneId: null,
        timezoneOffset: null
      },
      contact: {
        firstName: null,
        lastName: null,
        phoneNumber: null,
        address: null,
        city: null,
        state: null,
        zipCode: null,
        active: true,
        emailAddress: null,
      },
      customerRules: {
        name: { required, alphaNum },
        description: { required },
      },
      contactRules: {
        firstName: { required, alpha },
        lastName: { required, alpha },
        phoneNumber: { required, numeric },
        address: { required },
        city: { required },
        state: { required },
        zipCode: { required },
        emailAddress: { required, email },
      },
      customerValidate: null,
      contactValidate: null,
      selectedOption: null,
      options: []
    };
  },
  async mounted() {
    try {
      const response = await this.$http.get(`${this.config.VUE_APP_ENV_URL}/c5server/v1/timezones`);
      if (this.customerEdit !== null) {
        this.selectedOption = this.customerEdit.timezoneId.concat(": ").concat(this.customerEdit.timezoneOffset)
      }
      this.options = Object.entries(response.data).map(([timezone, offset]) => ({
        id: `${timezone}: ${offset}`,
        name: `${timezone}: ${offset}`
      }));
 
    } catch (error) {
      console.error('Failed to fetch timezones:', error);
    }
  },
  async created() {
    if (this.customerEdit) {
      this.customer = this.clone(this.customerEdit);
    }
  },
  methods: {
    addCustomer() {
      this.toast.showToast = false;
      const body = this.customer;
      body.contacts = [this.contact];
      this.$http
        .post(`${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/`, body)
        .then(this.toastSuccess('Customer added successfully!'))
        .catch((error) => this.toastError(error, 'Unable to add customer.'));
    },
    updateCustomer() {
      this.toast.showToast = false;
      const body = this.customer;
      this.$http
        .put(`${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/`, body)
        .then(this.toastSuccess('Customer updated successfully!'))
        .catch((error) => this.toastError(error, 'Unable to update customer.'));
    },
    deleteCustomer(id) {
      this.toast.showToast = false;
      this.$http
        .delete(`${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${id}`)
        .then(this.toastSuccess('Customer deleted successfully!'))
        .catch((error) => this.toastError(error, 'Unable to delete customer.'));
    },
    async handleSubmit(apiCall) {
      const customerValidate = useVuelidate(this.customerRules, this.customer);
      const contactValidate = useVuelidate(this.contactRules, this.contact);
      const resultCustomer = await customerValidate.value.$validate();
      const resultContact = await contactValidate.value.$validate();
      this.customerValidate = customerValidate;
      this.contactValidate = contactValidate;
      if (this.customerEdit && resultCustomer) {
        apiCall();
      }
      if (resultCustomer && resultContact) {
        apiCall();
      }
    },
    handleClose() {
      this.$emit('refresh');
      this.$emit('update', false);
    },
    handleChange() {
      if (this.selectedOption != null) {
        const [timezoneId, timezoneOffset] = this.selectedOption.split(': ');
        this.customer.timezoneId = timezoneId;
        this.customer.timezoneOffset = timezoneOffset;
      }
    },
  },
  components: {
    ToastSubmit,
    DialogWrapper,
    InputErrorWrapper,
    AddEditDeleteButtons,
  },
};
</script>
