<template>
  <v-card>
    <AdaptifyOverlay :showOverlay="this.showOverlay"/>
    <BoxHeader
      type="Users"
      :action="
        () => {
          userEdit = null;
          showModalAddEditUser = !showModalAddEditUser;
        }
      "
      @update="searchValue = $event" />
    <EasyTable
      alternating
      :items="users"
      buttons-pagination
      :rows-per-page="5"
      :fixed-header="false"
      :headers="userColumns"
      :hide-rows-per-page="true"
      v-model:search-value="searchValue"
      table-class-name="adaptifytable-table">
      <template #header="header">
        <div class="adaptifytable-header">
          {{ header.text }}
        </div>
      </template>
      <template #empty-message>
        <ErrorLoader
          :data="users"
          :error="error"
          type="Users"
          :loading="loading"
          v-if="loading || error" />
        <AdaptifyAlert
          v-else
          type="info"
          :message="`No Data/Matches Found`" />
      </template>
      <template #item-id="{ id }">
        <CiscoButton
          color="blue"
          type="pencil"
          :action="() => openEditUser(id)" />
      </template>
      <template #item-enabled="{ enabled, id }">
        <span>
          <label class="switch">
            <input
              v-if="enabled"
              type="checkbox"
              @click="this.enableDisableUser(id, false)"
              checked
              />
            <input
              v-else
              type="checkbox"
              @click="this.enableDisableUser(id, true)"
            />
            <span class="switch__input" />
          </label>
        </span>
      </template>
      <template #item-delete="{ id, username }">
        <CiscoButton
          color="blue"
          type="delete"
          :action="() => showConfirmModal(id, username)" />
      </template>
    </EasyTable>
  </v-card>
  <AdaptifyConfirm
      :showConfirm="showConfirm"
      @response="confirmDialog"
      :message="confirmMessage" />
  <AddEditUser
    @refresh="getUsers()"
    :userEdit="userEdit"
    v-if="showModalAddEditUser"
    v-model="showModalAddEditUser"
    :organizationId="organizationId"
    @update="showModalAddEditUser = $event"
    :showModalAddEditUser="showModalAddEditUser" />
  <br />
</template>

<script>
import BoxHeader from './BoxHeader.vue';
import EasyTable from 'vue3-easy-data-table';
import CiscoButton from '../commons/CiscoButton.vue';
import AdaptifyOverlay from '../commons/AdaptifyOverlay.vue';
import AddEditUser from './inputforms/AddEditUser.vue';
import AdaptifyAlert from '../commons/AdaptifyAlert.vue';
import AdaptifyConfirm from '../commons/AdaptifyConfirm.vue';
import ErrorLoader from '@/components/commons/ErrorLoader.vue';

export default {
  props: {
    organizationId: String,
  },
  data() {
    return {
      showOverlay: false,
      showConfirm: false,
      confirmResponse: null,
      confirmMessage: null,
      users: [],
      error: null,
      loading: false,
      userEdit: null,
      searchValue: '',
      idDeleteUser: null,
      showModalAddEditUser: false,
      userColumns: [
        {
          text: 'Username',
          value: 'username',
        },
        {
          text: 'Edit',
          value: 'id',
        },
        {
          text: 'First Name',
          value: 'firstName',
        },
        {
          text: 'Last Name',
          value: 'lastName',
        },
        {
          text: 'Role',
          value: 'roles',
        },
        {
          text: 'Enabled',
          value: 'enabled',
        },
        {
          text: 'Delete',
          value: 'delete',
        },
      ],
    };
  },
  async created() {
    this.getUsers();
  },
  methods: {
    getUsers() {
      this.loading = true;
      this.$http
        .get(
          `${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${this.organizationId}/user`
        )
        .then((response) => {
          this.users = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.error = `Unable to display users. Server responded with "${e.message}"`;
          console.error(e);
        });
    },
    openEditUser(edit) {
      this.loading = true;
      this.$http
        .get(
          `${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${this.organizationId}/user/${edit}`
        )
        .then((response) => {
          this.userEdit = response.data;
          this.showModalAddEditUser = true;
        })
        .catch((e) => {
          this.error = `Unable to get user info. Server responded with "${e.message}"`;
          console.error(e);
        });
      this.loading = false;
    },
    enableDisableUser(userId, enableDisable) {
      this.users[this.users.findIndex(user => user.id === userId)].enabled = enableDisable;
      this.showOverlay = true;
      const body = this.users[this.users.findIndex(user => user.id === userId)];
      this.$http
        .patch(
          `${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${this.organizationId}/user/${userId}`,
          body
        )
        .then(response => {
          this.showOverlay = false;
        })
        .catch((error) => {
          this.users[this.users.findIndex(user => user.id === userId)].enabled = !enableDisable;
          this.showOverlay = false;
        });
    },
    showConfirmModal(id, user) {
      this.confirmMessage = "Are you sure want to delete user: ".concat(user).concat("?");
      this.showConfirm = true;
      this.idDeleteUser = id;
    },
    deleteUser(userId) {
      this.showOverlay = true;
      this.$http
        .delete(
          `${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${this.organizationId}/user/${userId}`,
        )
        .then(response => {
          this.showOverlay = false;
          this.idDeleteUser = null;
          this.getUsers();
        })
        .catch((error) => {
          this.showOverlay = false;
        });
    },
    confirmDialog(response) {
      if (response) {
        this.deleteUser(this.idDeleteUser);
      }
      this.showConfirm = false;
      this.deleteUser = null;
    },
  },
  components: {
    BoxHeader,
    EasyTable,
    ErrorLoader,
    CiscoButton,
    AddEditUser,
    AdaptifyAlert,
    AdaptifyOverlay,
    AdaptifyConfirm
  },
};
</script>
