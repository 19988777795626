<template>
  <v-dialog
    width="auto"
    v-if="showReports"
    persistent
    scrollable
  >
    <v-card width="100%" class="form-group base-margin-bottom">
      <v-toolbar>
        <v-toolbar-title class="text-size-24">Manual Reports</v-toolbar-title>
        <v-toolbar-items>
          <v-btn @click="this.handleClose()">
            <div :class="`icon-close icon-size-24`" />
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-tabs
        v-model="tab"
        show-arrows="always"
        next-icon="icon-chevron-right"
        prev-icon="icon-chevron-left">
        <v-tab
            class="v-tab-edit"
            :click="this.clearMsgs()"
            :key="0">
            Interval Report
        </v-tab>
        <v-tab
            class="v-tab-edit"
            :click="this.clearMsgs()"
            :key="1">
            Daily Report
        </v-tab>
        <v-tab
            v-if="reportType === 'Aspect'"
            class="v-tab-edit"
            :click="this.clearMsgs()"
            :key="2">
            Intra-Daily Report
        </v-tab>
      </v-tabs>

      <v-card-text>
        <div class="cui">
          <ToastSubmit :toastData="toast" v-if="toast.showToast"/>
        </div>
        <br/>
        <br/>
        <v-window v-model="tab" continuous>
          <v-window-item class="text-size-24" :key="0">
            <v-card width="auto" class="cui">
              <template v-slot:title>
              </template>
              <template v-slot:text>
                <div
                  class="form-group base-margin-bottom">
                  <div class="row">
                    <div class="col-md-3">
                      <h5>Range</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <InputErrorWrapper
                        property="rangeIntervalReport"
                        :validate="validate">
                      <div class="form-group">
                          <label class="radio">
                              <input type="radio" name="radio-inline_0"  v-model="this.reports.rangeIntervalReport" value="1"/>
                              <span class="radio__input"></span>
                              <span class="radio__label">Single Interval</span>
                          </label>
                      </div>
                      <div class="form-group">
                          <label class="radio">
                            <input type="radio" name="radio-inline_0" v-model="this.reports.rangeIntervalReport" value="2"/>
                              <span class="radio__input"></span>
                              <span class="radio__label">Multiple Intervals</span>
                          </label>
                      </div>
                      </InputErrorWrapper>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <h5>Date/Time</h5>
                    </div>
                  </div>
            
                  <div class="row">
                    <div class="col-md-6">
                      <InputErrorWrapper
                        property="startDateIntervalReport"
                        :validate="validate">
                        <div class="form-group__text">
                          <input
                            type="date"
                            v-model="reports.startDateIntervalReport" />
                          <label for="input-type-date">Start Date</label>
                        </div>
                      </InputErrorWrapper>
                    </div>
                    <div class="col-md-6">
                      <InputErrorWrapper
                        property="startTimeIntervalReport"
                        :validate="validate">
                        <div class="form-group__text">
                          <input
                            type="time"
                            v-model="reports.startTimeIntervalReport" />
                          <label for="input-type-date">Start Time</label>
                        </div>
                      </InputErrorWrapper>
                    </div>
                  </div>
                 
                  <div class="row">
                    <div class="col-md-6">
                      <InputErrorWrapper
                        property="stopDateIntervalReport"
                        :validate="validate">
                        <div class="form-group__text">
                          <input
                            type="date"
                            :disabled="this.reports.rangeIntervalReport === '2' ? disabled : ''"
                            v-model="reports.stopDateIntervalReport" />
                          <label for="input-type-date">Stop Date</label>
                        </div>
                      </InputErrorWrapper>
                    </div>
                    <div class="col-md-6">
                      <InputErrorWrapper
                        property="stopTimeIntervalReport"
                        :validate="validate">
                        <div class="form-group__text">
                          <input
                            type="time"
                            :disabled="this.reports.rangeIntervalReport === '2' ? disabled : ''"
                            v-model="reports.stopTimeIntervalReport" />
                            <label for="input-type-date">Stop Time</label>
                        </div>
                      </InputErrorWrapper>
                    </div>
                  </div>
                  <div class="row"> 
                    <div class="col-md-6">
                      <div v-if="showUpdateButton">
                        <CiscoButton
                          type="refresh"
                          color="blue"
                          label="Save"
                          :action="() => handleSubmit(updateReports,0)" />
                      </div>
                      <div v-if="!showUpdateButton">
                        <div
                          class="loading-dots"
                          aria-label="Loading, please wait...">
                        <span
                          v-for="index in 5"
                          :key="index" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div v-if="showGenerateButton">
                        <CiscoButton
                          type="refresh"
                          color="blue"
                          label="Generate"
                          :action="() => generateReport(0)" />
                      </div>
                      <div v-if="showPollingMessage" class="flex-inline" style="margin-top: 1rem;">
                        <div
                          :class="`${ iconFigure() } icon-size-24`"
                          :style="`color: ${ handlePollingMessageColor() };`"/>
                          <h6
                            class="polling-message"
                            :style="`color: ${ handlePollingMessageColor() };`"
                          >{{ this.pollingMessage }}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </v-card>
          </v-window-item>
          <v-window-item class="text-size-24" :key="1">
            <v-card width="auto" class="cui">
              <template v-slot:title>
              </template>
              <template v-slot:text>
                <div
                  class="form-group base-margin-bottom" >
                  <div class="row">
                    <div class="col-md-3">
                      <h5>Range</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <InputErrorWrapper
                        property="rangeDailyReport"
                        :validate="validate">
                      <div class="form-group">
                          <label class="radio">
                              <input type="radio" name="radio-inline_0"  v-model="this.reports.rangeDailyReport" value="1"/>
                              <span class="radio__input"></span>
                              <span class="radio__label">Single Day</span>
                          </label>
                      </div>
                      <div class="form-group">
                          <label class="radio">
                              <input type="radio" name="radio-inline_0" v-model="this.reports.rangeDailyReport" value="2"/>
                              <span class="radio__input"></span>
                              <span class="radio__label">Multiple Days</span>
                          </label>
                      </div>
                      </InputErrorWrapper>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <InputErrorWrapper
                        property="startDateDailyReport"
                        :validate="validate">
                        <div class="form-group__text">
                          <input
                            type="date"
                            v-model="reports.startDateDailyReport" />
                          <label for="input-type-date">Start Date</label>
                        </div>
                      </InputErrorWrapper>
                    </div>
                    <div class="col-md-6" v-if="(this.reports.rangeDailyReport != 1)">
                      <InputErrorWrapper
                        property="stopDateDailyReport"
                        :validate="validate">
                        <div class="form-group__text">
                          <input
                            type="date"
                            v-model="reports.stopDateDailyReport" />
                          <label for="input-type-date">Stop Date</label>
                        </div>
                      </InputErrorWrapper>
                    </div>
                  </div>
                  <div class="row"> 
                    <div class="col-md-6">
                      <div v-if="showUpdateButton">
                        <CiscoButton
                          type="refresh"
                          color="blue"
                          label="Save"
                          :action="() => handleSubmit(updateReports,1)" />
                      </div>
                      <div v-if="!showUpdateButton">
                        <div
                          class="loading-dots"
                          aria-label="Loading, please wait...">
                        <span
                          v-for="index in 5"
                          :key="index" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div v-if="showGenerateButton">
                        <CiscoButton
                          type="refresh"
                          color="blue"
                          label="Generate"
                          :action="() => generateReport(1)" />
                      </div>
                      <div v-if="showPollingMessage" class="flex-inline" style="margin-top: 1rem;">
                        <div
                          :class="`${ iconFigure() } icon-size-24`"
                          :style="`color: ${ handlePollingMessageColor() };`"/>
                          <h6
                            class="polling-message"
                            :style="`color: ${ handlePollingMessageColor() };`"
                          >{{ this.pollingMessage }}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </v-card>
          </v-window-item>
          <v-window-item class="text-size-24" :key="2">
            <v-card width="auto" class="cui">
              <template v-slot:title>
              </template>
              <template v-slot:text>
                <div
                  class="form-group base-margin-bottom" >
                  <div class="row">
                    <div class="col-md-3">
                      <h5>Range</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <InputErrorWrapper
                        property="rangeIntraDaily"
                        :validate="validate">
                      <div class="form-group form-group--inline">
                          <label class="radio">
                            <input type="radio" name="radio-inline_0" v-model="this.reports.rangeIntraDaily" value="1"/>
                              <span class="radio__input"></span>
                              <span class="radio__label">Single Report</span>
                          </label>
                      </div>
                      <div class="form-group form-group--inline">
                          <label class="radio">
                            <input type="radio" name="radio-inline_0" v-model="this.reports.rangeIntraDaily" value="2"/>
                              <span class="radio__input"></span>
                              <span class="radio__label">Multiple Reports</span>
                          </label>
                      </div>
                      </InputErrorWrapper>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">
                      <h5>Date/Time</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <InputErrorWrapper
                        property="startTimeIntraDaily"
                        :validate="validate">
                        <div class="form-group__text">
                          <input
                            type="time"
                            v-model="reports.startTimeIntraDaily" />
                          <label for="input-type-date">Start Time</label>
                        </div>
                      </InputErrorWrapper>
                    </div>
                    <div class="col-md-6">
                      <InputErrorWrapper
                        property="stopTimeIntraDaily"
                        :validate="validate">
                        <div class="form-group__text">
                          <input
                            type="time"
                            v-model="reports.stopTimeIntraDaily" />
                          <label for="input-type-date">Stop Time</label>
                        </div>
                      </InputErrorWrapper>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <InputErrorWrapper
                        property="startDateIntraDaily"
                        :validate="validate">
                        <div class="form-group__text">
                          <input
                            type="date"
                            v-model="reports.startDateIntraDaily" />
                          <label for="input-type-date">Start Date</label>
                        </div>
                      </InputErrorWrapper>
                    </div>
                    <div class="col-md-6">
                      <InputErrorWrapper
                        property="stopDateIntraDaily"
                        :validate="validate">
                        <div class="form-group__text">
                          <input
                            type="date"
                            :disabled="this.reports.rangeIntraDaily === '2' ? disabled : ''"
                            v-model="reports.stopDateIntraDaily" />
                          <label for="input-type-date">Stop Date</label>
                        </div>
                      </InputErrorWrapper>
                    </div>
                  </div>
                  <div class="row"> 
                    <div class="col-md-6">
                      <div v-if="showUpdateButton">
                        <CiscoButton
                          type="refresh"
                          color="blue"
                          label="Save"
                          :action="() => handleSubmit(updateReports,2)" />
                      </div>
                      <div v-if="!showUpdateButton">
                        <div
                          class="loading-dots"
                          aria-label="Loading, please wait...">
                        <span
                          v-for="index in 5"
                          :key="index" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div v-if="showGenerateButton">
                        <CiscoButton
                          type="refresh"
                          color="blue"
                          label="Generate"
                              :action="() => generateReport(2)" />
                      </div>
                      <div v-if="showPollingMessage" class="flex-inline" style="margin-top: 1rem;">
                        <div
                          :class="`${ iconFigure() } icon-size-24`"
                          :style="`color: ${ handlePollingMessageColor() };`"/>
                          <h6
                            class="polling-message"
                            :style="`color: ${ handlePollingMessageColor() };`"
                          >{{ this.pollingMessage }}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </v-card>
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>
</v-dialog>
</template>

<script>
import { toRef } from 'vue';
import CiscoButton from '../../commons/CiscoButton.vue';
import ToastSubmit from './ToastSubmit.vue';
import useVuelidate from '@vuelidate/core';
import { integer, required, minValue, requiredUnless, helpers } from '@vuelidate/validators';
import InputErrorWrapper from './InputErrorWrapper.vue';

export default {
  props: {
    manualReportsIndex: integer,
    manualReportType: String,
    productInstance: Object,
    showReports: Boolean,
  },
  setup(props) {
    const options = ['ManualReportSettings'];
    const productInstanceTmp = toRef(props, 'productInstance');
    const reportType = toRef(props, 'manualReportType');
    return {
      options,
      productInstanceTmp,
      reportType
    };
  },
  name: "InstanceConfiguration",
  methods: {
    updateReports(reportIndex) {
      this.showPollingMessage = false;
      const body = this.productInstanceTmp;
      const manualReportSettings = this.productInstanceTmp.productInstanceConfiguration.configurations[this.manualReportsIndex].productVariantPropsGroups[
        'ManualReportSettings'
      ];
      const stopDateTimeKey = this.getPropertyIndexByName('stopDateTime');
      const startDateTimeKey = this.getPropertyIndexByName('startDateTime');
      switch (reportIndex) {
        case 0:
          if (this.reports['rangeIntervalReport'] === '1') {
            manualReportSettings[stopDateTimeKey].propValue = '';
          }
          if (this.reports['rangeIntervalReport'] === '2') {
            manualReportSettings[stopDateTimeKey].propValue = this.reports['stopDateIntervalReport']
              .concat('T')
              .concat(this.reports['stopTimeIntervalReport']);
          }
          manualReportSettings[startDateTimeKey].propValue = this.reports['startDateIntervalReport']
            .concat('T')
            .concat(this.reports['startTimeIntervalReport']);
          break;
        case 1:
          manualReportSettings[startDateTimeKey].propValue = this.reports['startDateDailyReport'].concat('T').concat('00:00:00');
          if (this.reports['rangeDailyReport'] === '1') {
            this.reports['stopDateDailyReport'] = '';
          }
          if (this.reports['rangeDailyReport'] === '2') {
            manualReportSettings[stopDateTimeKey].propValue = this.reports['stopDateDailyReport'].concat('T').concat('00:00:00');
          }
          break;
        case 2:
          if (this.reports['rangeIntraDaily'] === '1') {
            this.reports['stopDateIntraDaily'] = '';
            this.reports['stopTimeIntraDaily'] = '';
          }
          manualReportSettings[this.getPropertyIndexByName('manualIntraDailyStartTime')].propValue = this.reports['startTimeIntraDaily'];
          manualReportSettings[this.getPropertyIndexByName('manualIntraDailyStopTime')].propValue = this.reports['stopTimeIntraDaily'];
          manualReportSettings[this.getPropertyIndexByName('manualIntraDailyStartDate')].propValue = this.reports['startDateIntraDaily'];
          manualReportSettings[this.getPropertyIndexByName('manualIntraDailyStopDate')].propValue = this.reports['stopDateIntraDaily'];
          break;
      }
      body.productInstanceConfiguration.configurations[this.manualReportsIndex].productVariantPropsGroups['ManualReportSettings'] = manualReportSettings;
      this.showGenerateButton = false;
      this.showUpdateButton = false;
      this.$http
        .put(
          `${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${this.$keycloak.tokenParsed.organizationId}/productinstance`,
          body
        )
        .then(response => {
          this.toastSuccess('Report configuration updated successfully!');
          this.showGenerateButton = true,
          this.showUpdateButton = true;
        })
        .catch((error) => {
          this.showUpdateButton = true;
          this.toastError(error, 'Unable to update report configuration.')
        });
    },

    getCommandType(reportType) {
      const reportEndPoint = ["intervalreport", "dailyreport", "intradailyreport"];
      return this.$http
        .get(
          `${this.config.VUE_APP_ENV_URL}/c5server/v1/commandType/name/${reportEndPoint[reportType]}`
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.error(error);
          return null;
        });
    },

    executeCommandType(bodyResponse, controllerId, productInstanceId, organizationId) {
      const body = {
        commandType: bodyResponse,
        organizationId: organizationId,
        controllerId: controllerId,
        productInstanceId: productInstanceId,
        controllerRegistrationKey: "",
        controllerConfigUri: "",
      };
      return this.$http
        .post(
          `${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${organizationId}/command`,
          body
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.error(error);
          return null;
        });
    },

    getCommandStatus(uri) {
      return this.$http.get(uri)
        .then(response => response.data)
        .catch((error) => {
          console.error(error);
          return null;
        });
    },

    async generateReport(reportType) {
      this.showGenerateButton = false;
      this.showPollingMessage = true;
      this.pollingMessage = "Generating report"
      const commandType = await this.getCommandType(reportType);
      if (commandType === null) {
        this.showAlert = true;
        return;
      }
      const execCommandType = await this.executeCommandType(
        commandType,
        this.productInstanceTmp.controllerId,
        this.productInstanceTmp.productInstanceId,
        this.productInstanceTmp.organizationId
      );
      const startDateT = new Date();
      const interval = setInterval(async() => {
        if (this.getSecondsBetweenStartDateAndCurrentDate(startDateT) >= 90) {
          this.pollingMessage = "Time out";
          clearInterval(interval);
        } else {
          const commandStatus =  await this.getCommandStatus(`${this.config.VUE_APP_ENV_URL}${execCommandType.commandStatusUri}`);
          if (commandStatus === null || commandStatus === undefined) {
            this.pollingMessage = "Cannot get report status"
            this.showGenerateButton = true;
            clearInterval(interval);
          }
          this.pollingMessage = commandStatus.commandStatusCodeDto.description;
          switch (commandStatus.commandStatusCodeDto.commandStatusCode) {
            case this.status.ReportExecuted:
              this.showGenerateButton = true;
              clearInterval(interval);
              break;
            default:
              break;
          }
        }
      }, 15000);
    },

    getSecondsBetweenStartDateAndCurrentDate(startDateT) {
      return ((new Date().getTime() - startDateT.getTime()) / 1000);
    },

    getPropertyIndexByName(name) {
      return this.productInstanceTmp.productInstanceConfiguration.configurations[this.manualReportsIndex].productVariantPropsGroups['ManualReportSettings'].findIndex(config => config.propName === name);
    },

    clearRules() {
      for (const key in this.rules) {
        this.rules[key] = { };
      }
    },
    
    createRules(reportType) {
      this.clearRules();
      switch (reportType) {
        case 0:
          this.rules.rangeIntervalReport = { required }
          this.rules.startDateIntervalReport = { required }
          this.rules.startTimeIntervalReport = { required }
          this.rules.stopTimeIntervalReport =  this.reports.rangeIntervalReport === "1" ? {} : { required }
          this.rules.stopDateIntervalReport = { 
            requiredIfSingle: helpers.withMessage('In MultipleReports, value is required', requiredUnless(val => {
              return (this.reports.rangeIntervalReport === "1");
            })),
            minValue: helpers.withMessage('Stop date cannot be less than start date', val => {
              if (this.reports.rangeIntervalReport === "1") {
                return true;
              }
              return (new Date(this.reports.stopDateIntervalReport) >= new Date(this.reports.startDateIntervalReport));
            })
          }
          break;
        case 1:
          this.rules.rangeDailyReport = { required }
          this.rules.startDateDailyReport = { required }
          this.rules.stopDateDailyReport = { 
            requiredIfSingle: helpers.withMessage('In MultipleReports, value is required', requiredUnless(val => {
              return (this.reports.rangeDailyReport === "1");
            })),
            minValue: helpers.withMessage('Stop date cannot be less than start date', val => {
              if (this.reports.rangeDailyReport === "1") {
                return true;
              }
              return (new Date(this.reports.stopDateDailyReport) >= new Date(this.reports.startDateDailyReport));
            })
          }
          break;
        case 2:
          this.rules.rangeIntraDaily = { required }
          this.rules.startDateIntraDaily = { required }
          this.rules.startTimeIntraDaily = { required }
          this.rules.stopTimeIntraDaily =  this.reports.rangeIntraDaily === "1" ? {} : { required }
          this.rules.stopDateIntraDaily = { 
            requiredIfSingle: helpers.withMessage('In MultipleReports, value required', requiredUnless(val => {
              return (this.reports.rangeIntraDaily === "1");
            })),
            minValue: helpers.withMessage('Stop date cannot be less than start date', val => {
              if (this.reports.rangeIntraDaily === "1") {
                return true;
              }
              return (new Date(this.reports.stopDateIntraDaily) >= new Date(this.reports.startDateIntraDaily));
            })
          }
          break;
        default:
          console.error("Unsopported report.");
      }
    }, 
    clearMsgs() {
      this.toast.showToast = false;
      this.showPollingMessage = false;
    },
    handleClose() {
      this.toastSuccess();
      this.$emit('refresh');
      this.$emit('update', false);
    },
    async handleSubmit(apiCall, reportType) {
      this.createRules(reportType);
      this.toastSuccess();
      const validator = useVuelidate(this.rules, this.reports);
      const result = await validator.value.$validate();
      this.validate = validator;
      if (result) {
        this.toastSuccess();
        apiCall(reportType);
      }
    },
    iconFigure() {
      if (this.pollingMessage === 'Generating report') {
        return 'icon-file-text-o';
      } else if (this.pollingMessage === 'Time out' || this.pollingMessage === 'Cannot get report status') {
        return 'icon-warning-circle';
      } else {
        return 'icon-info';
      }
    },
    handlePollingMessageColor() {
      if (this.pollingMessage === 'Time out' || this.pollingMessage === 'Cannot get report status') {
        return 'yellow';
      } else {
        return '#64bbe3'
      }
    },
  },   
  data() {
    const status = Object.freeze({
      Loading: "",
      AdapterNotConfigured: "Adapter is not Configured",
      ControllerNotConfigured: "Controller is not Configured",
      OK: "Ok",
      Error: "error",
      Timeout: "Timeout",
      Started: "started",
      Stopped: "stopped",
      ReportExecuted: "manualreportexecuted"
    });
    return {
      status,
      reports: {
        rangeIntervalReport: null,
        startDateIntervalReport: null,
        stopDateIntervalReport: null,
        startTimeIntervalReport: null,
        stopTimeIntervalReport: null,
        rangeDailyReport: null,
        startDateDailyReport: null,
        stopDateDailyReport: null,
        rangeIntraDaily: null,
        startDateIntraDaily: null,
        stopDateIntraDaily: null,
        startTimeIntraDaily: null,
        stopTimeIntraDaily: null,
      },
      rules: {
        rangeIntervalReport: { },
        startDateIntervalReport: { },
        stopDateIntervalReport: { },
        startTimeIntervalReport: { },
        stopTimeIntervalReport: { },
        rangeDailyReport: { },
        startDateDailyReport: { },
        stopDateDailyReport: { },
        rangeIntraDaily: { },
        startDateIntraDaily: { },
        stopDateIntraDaily: { },
        startTimeIntraDaily: { },
        stopTimeIntraDaily: { },
      },
      validateDailyReport: null,
      validateIntradailyReport: null,
      validateIntervalReport: null,
      tabIndex: 0,
      tab: null,
      showUpdateButton: true,
      showGenerateButton: false,
      showPollingMessage: false,
      pollingMessage: null,
      validate: null,
    };
  },
  components: { CiscoButton, ToastSubmit, InputErrorWrapper },
};
</script>
<style scoped>
.v-tab-edit {
  height: 20px;
  color: #65bbe3;
  background-color: white;
  font-size: medium; 
  border: 2rem;
}
.v-toolbar {
  color: white;
  background-color: #64bbe3;
}
.v-tab-edit:hover {
  height: 40px;
  color: #65bbe3;
  background-color: white;
}
.polling-message {
  margin-left: 1rem;
  text-align: center;
}
.polling-message-div {
  margin-top: 1rem;
}
</style>
