<template>
<div>
    <nav
      class="header"
      id="styleguideheader"
      role="navigation">
      <Notifications ref="modal_notifications"/>
      <CustomerProfile
        ref="modal"
        :currentUser="currentUser" />
      <div class="container-fluid">
        <div class="header-panels">
          <div class="header-panel hidden-md-down">
            <a
              class="header__logo"
              href="http://www.cisco.com"
              target="_blank">
              <span class="icon-cisco" />
            </a>
            <h1
              class="header__title"
              style="margin-left: 50%">
              <span v-if="this.organization">Organization: {{ organization.name }}</span>
            </h1>
          </div>
          <div class="header-panel header-panel--right hidden-md-down">
            <a
              href="/adminhome"
              class="header-item"
              title="Home"
              ><span class="icon-home"></span>
            </a>
            <a
              href="#/"
              class="header-item"
              title="Activities"
              @click.prevent="showNotifications()"
              ><span class="icon-report"></span>
            </a>
            <a
              href="/customers"
              class="header-item"
              >Customers
            </a>
            <a
              href="/products"
              class="header-item"
              >Services
            </a>
            <a
              href="#/home"
              class="header-item"
              title="Home"
              @click.prevent="showProfile()">
              {{ this.currentUser !== null ? this.currentUser.firstName + " " + this.currentUser.lastName : "N/A" }}
            </a>
            <a
              class="header-item"
              @click="logoutEvent()">
              Logout
            </a>
          </div>
        </div>
      </div>
    </nav>
    <br />
  </div>
</template>

<script>
import Notifications from '../views/Notifications.vue';
import CustomerProfile from './databoxes/inputforms/CustomerProfile.vue';

export default {
  data() {
    return {
      currentUser: null,
      organization: null,
    };
  },
  async created() {
    await this.getCurrentUser();
    await this.getOrganization();
  },
  setup() {
    return {
      logout: () =>
        this.$keycloak.logout({ redirectUri: `${this.config.VUE_APP_REDIRECT_URI}` }),
    };
  },
  methods: {
    showNotifications() {
      this.$refs.modal_notifications.handleModal();
    },
    showProfile() {
      this.$refs.modal.handleModal();
    },
    getOrganization() {
      this.$http
        .get(
          `${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${this.$keycloak.tokenParsed.organizationId}`
        )
        .then((response) => (this.organization = response.data))
        .catch((e) => console.error(e));
    },
    getCurrentUser() {
      this.$http
        .get(
          `${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${this.$keycloak.tokenParsed.organizationId}/user/${this.$keycloak.tokenParsed.sub}`
        )
        .then((response) => (this.currentUser = response.data))
        .catch((error) => console.error(error));
    },
    logoutEvent() {
      localStorage.setItem('logout-event', `logout${Math.random()})`);
      this.$keycloak.logout({ redirectUri: this.redirectUri });
    }
  },
  components: {
    Notifications,
    CustomerProfile,
  },
};
</script>
