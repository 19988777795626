<template>
  <div className="section">
    <div v-if="!loading && error">
      <AdaptifyAlert
        type="warning"
        :message="error" />
    </div>
    <span v-if="loading">
      <div
        class="loading-dots"
        aria-label="Loading, please wait...">
        <span
          v-for="index in 5"
          :key="index" />
      </div>
    </span>
  </div>
</template>

<script>
import AdaptifyAlert from './AdaptifyAlert.vue';

export default {
  props: {
    data: Object,
    type: String,
    error: String,
    loading: Boolean,
  },
  components: {
    AdaptifyAlert,
  },
};
</script>
