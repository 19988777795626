<template>
  <v-card>
    <AdaptifyOverlay :showOverlay="this.showOverlay"/>
    <BoxHeader
      type="Customers"
      :action="
        () => {
          customerEdit = null;
          showModalAddEditCustomer = !showModalAddEditCustomer;
        }
      "
      @update="searchValue = $event" />
    <EasyTable
      alternating
      buttons-pagination
      :rows-per-page="5"
      :items="customers"
      :fixed-header="false"
      :hide-rows-per-page="true"
      :headers="customerColumns"
      v-model:search-value="searchValue"
      table-class-name="adaptifytable-table">
      <template #header="header">
        <div class="adaptifytable-header">
          {{ header.text }}
        </div>
      </template>
      <template #empty-message>
        <ErrorLoader
          :error="error"
          :data="customers"
          :type="`Customers`"
          :loading="loading"
          v-if="loading || error" />
        <AdaptifyAlert
          v-else
          type="info"
          :message="`No Data/Matches Found`" />
      </template>
      <template #item-organizationId="{ organizationId }">
        <CiscoButton
          type="pencil"
          color="blue"
          :action="() => openEditCustomer(organizationId)" />
        <CiscoButton
          type="add-contact"
          color="blue"
          :action="
            () => {
              userEdit = null;
              usersOrgId = organizationId;
              showModalAddEditUser = !showModalAddEditUser;
            }
          " />
        <CiscoButton
          type="more"
          color="blue"
          :action="() => viewCustomer(organizationId)" />
      </template>
      <template #item-active="{ active, organizationId }">
        <span>
          <label class="switch">
            <input
              v-if="active"
              type="checkbox"
              checked
              @click="this.enableDisableCustomer(organizationId, false)" />
            <input
              v-else
              type="checkbox"
              @click="this.enableDisableCustomer(organizationId, true)" />
            <span class="switch__input" />
          </label>
        </span>
      </template>
    </EasyTable>
    <AddEditUser
      :userEdit="userEdit"
      v-if="showModalAddEditUser"
      v-model="showModalAddEditUser"
      @refresh="getCustomers()"
      :organizationId="usersOrgId"
      :showModalAddEditUser="showModalAddEditUser"
      @update="showModalAddEditUser = $event" />
  </v-card>
  <AddEditCustomer
    :customerEdit="customerEdit"
    v-if="showModalAddEditCustomer"
    v-model="showModalAddEditCustomer"
    @refresh="getCustomers()"
    @update="showModalAddEditCustomer = $event"
    :showModalAddEditCustomer="showModalAddEditCustomer" />
  <br />
</template>

<script>
import { useRouter } from 'vue-router';
import BoxHeader from './BoxHeader.vue';
import EasyTable from 'vue3-easy-data-table';
import CiscoButton from '../commons/CiscoButton.vue';
import AdaptifyOverlay from '../commons/AdaptifyOverlay.vue';
import AddEditUser from './inputforms/AddEditUser.vue';
import ErrorLoader from '@/components/commons/ErrorLoader.vue';
import AddEditCustomer from './inputforms/AddEditCustomer.vue';
import AdaptifyAlert from '../commons/AdaptifyAlert.vue';
export default {
  props: {
    organizationId: String,
  },
  data() {
    return {
      error: null,
      customers: [],
      userEdit: null,
      loading: false,
      searchValue: '',
      usersOrgId: null,
      customerEdit: null,
      showModalAddEditUser: false,
      showModalAddEditCustomer: false,
      showOverlay: false,
      customerColumns: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Edit',
          value: 'organizationId',
        },
        {
          text: 'Active',
          value: 'active',
        },
        {
          text: 'Description',
          value: 'description',
        },
        {
          text: 'Created Date',
          value: 'createdDate',
        },
      ],
    };
  },
  async created() {
    await this.getCustomers();
  },
  methods: {
    getCustomers() {
      this.loading = true;
      this.$http
        .get(`${this.config.VUE_APP_ENV_URL}/c5server/v1/organization`)
        .then((response) => {
          this.customers = response.data;
        })
        .catch((e) => {
          this.error = `Unable to display customers. Server responded with "${e.message}"`;
          console.error(e);
        });
      this.loading = false;
    },
    openEditCustomer(edit) {
      this.loading = true;
      this.$http
        .get(`${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/${edit}`)
        .then((response) => {
          this.customerEdit = response.data;
          this.showModalAddEditCustomer = true;
        })
        .catch((e) => {
          this.error = `Unable to get customer info. Server responded with "${e.message}"`;
          console.error(e);
        });
      this.loading = false;
    },
    enableDisableCustomer(organizationId, enableDisable) {
      this.customers[this.customers.findIndex(customer => customer.organizationId === organizationId)].active = enableDisable;
      this.showOverlay = true;
      const body = this.customers[this.customers.findIndex(customer => customer.organizationId === organizationId)];
      this.$http
        .put(`${this.config.VUE_APP_ENV_URL}/c5server/v1/organization/`, body)
        .then(response => {
          this.showOverlay = false;
        })
        .catch((error) => {
          this.customers[this.customers.findIndex(customer => customer.organizationId === organizationId)].active = !enableDisable;
          this.showOverlay = false;
        });        
    },
  },
  setup() {
    const router = useRouter();
    return {
      viewCustomer: (organizationId) => router.push(`/customer/${organizationId}`),
    };
  },
  components: {
    BoxHeader,
    EasyTable,
    ErrorLoader,
    AddEditUser,
    CiscoButton,
    AddEditCustomer,
    AdaptifyAlert,
    AdaptifyOverlay
  },
};
</script>
